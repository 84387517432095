import React, { useState, useEffect } from 'react';
import { Form, useNavigate } from 'react-router-dom';
import AuthenticateService from './AuthenticateService';
import Sidebar from './Sidebar';
import Header from './Header';
import { Button, Card, FormGroup, Modal, Spinner } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import NumberFormater from './extra/Formater';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import cardTemplate from './../../assets/img/card/card design.png';
import applyCard from './../../assets/img/card/apply_card.png';
import Select from 'react-select'
import PreLoader from './extra/PreLoader';






const AtmCard = () => {

    const { http, user } = AuthenticateService();
    const [userDetail, setUser] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { token, logout } = AuthenticateService();
    const [isApplying, setIsApplying] = useState(false);
    const [isApplyingPhysicalCardModal, setIsApplyingPhysicalCardModal] = useState(false);
    const [isApplyingVirtualCardModal, setIsApplyingVirtualCardModal] = useState(false);
    const [cardtype, setCardType] = useState('');
    const [address, setAddress] = useState('');
    const [isBuying, setIsBuying] = useState(false);


    const getUserDetails = async () => {
        try {
            const response = await http.get('/users/get-details', {
                // params: { page: 1 },
            });
            if (response.status === 200) {
                const data = response.data.data;
                // console.log(data);
                setUser(data);
                setIsLoading(false)
            } else {
                // console.log('Error: Non-200 status code');
                logout()
            }
        } catch (error) {
            // logout();
            if (error.response) {
                if (error.response.status == 401) {
                    logout();
                }
            } else {
                toast.error('Something Went Wrong')
            }
        }
    };


    const tabs = [
        { id: 'virtual', label: 'Virtual Card', data: [], color: 'success' },
        { id: 'physical', label: 'Physcal Card', data: [], color: 'success' },

    ];

    const cardType = [
        { type: 'master', name: 'Master' },
        { type: 'visa', name: 'Visa' },
        { type: 'verve', name: 'Verve' },
        { type: 'gold', name: 'Golden' },
    ];

    // Initialize active tab state
    const [activeTab, setActiveTab] = useState('virtual');

    // Function to switch between tabs
    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
        // console.log(tabId);
    };
    useEffect(() => {
        document.title = user.first_name + " " + user.last_name + "- Manage Credit Card"; // Set the page title here

        getUserDetails();

    }, []);



    const cardStyle = {
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        maxWidth: '400px',
        margin: '0 auto',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        backgroundColor: '#f1f1f1',
        backgroundImage: 'url("static/media/card design.b95d4ce1643a851f1446.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    };

    const cardContentStyle = {
        // textAlign: 'center',
        color: '#fff',
        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.6)',
    };

    const handlePhysicalCard = () => {

    }

    const maskCardNumber = (cardNumber) => {
        const maskedDigits = 'X'.repeat(cardNumber.length - 9); // Calculate the number of digits to mask
        const visibleDigits = `${cardNumber.slice(0, 4)}${maskedDigits}${cardNumber.slice(-5)}`;
        return visibleDigits;
    };

    // console.log(user);

    return (

        <>
            {isLoading ? (
                <PreLoader />

            ) : (
                <>
                    <Sidebar />
                    <Header user={user} />


                    <div className="content">
                        <div className="pb-5">
                            <h3 className='text-uppercase'> {activeTab} card Application</h3>





                            <ul className="nav nav-phoenix-pills mb-5 d-sm-non d-xl-flex my-3" id="contactListTab" data-chat-thread-tab="data-chat-thread-tab" role="tablist">
                                {tabs.map((tab, index) => (
                                    <li className="nav-item" role="presentation" key={index}>
                                        <a className={`nav-link cursor-pointer ${activeTab === tab.id ? 'active' : ''}`}
                                            data-bs-toggle="tab"
                                            data-chat-thread-list={tab.label.toLowerCase()}
                                            role="tab"
                                            aria-selected={activeTab === tab.id ? 'active' : ''}
                                            onClick={() => {
                                                handleTabChange(tab.id);
                                            }}
                                        >
                                            {tab.label}

                                        </a>
                                    </li>
                                ))}
                            </ul>

                            <div className="row g-4" style={{ margin: '0px' }}>
                                <div className="col-12 col-xxl-6">


                                    {activeTab == 'virtual' && (


                                        <div className="row g-3">



                                            <h1>Apply {activeTab} card</h1>
                                            <div className='col-md-6'>
                                                <img className='card-img w-70' src={applyCard} alt='Loan Flier Image' />
                                            </div>



                                            <div className='col-md-6'>

                                                {userDetail.cards.length > 0 && userDetail.cards.some(card => card.card_category_id === 1) ? (
                                                    userDetail.cards.map((card, index) => (


                                                        <>
                                                            <div className="btn-reveal-trigger position-relative rounded-2 overflow-hidden p-4" styl={{ height: '236px' }}>
                                                                <div className="bg-holder"
                                                                    style={{ backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 39.41%, rgba(0, 0, 0, 0.4) 100%), url("static/media/card design.b95d4ce1643a851f1446.png")' }}
                                                                />
                                                                <div className="position-relative h-100 d-flex flex-column justify-content-between">
                                                                    <div className="justify-content-between align-items-center">

                                                                        <div className="z-index-2">
                                                                            <h3 className='text-white text-center'>{card.card_name}</h3>
                                                                        </div>

                                                                        <p className='text-light text-center' style={{ position: 'relative', top: '25px' }}>{maskCardNumber(card.card_number)}</p>
                                                                        <div className='text-white'>
                                                                            <p className='' style={{ position: 'relative', top: '33px' }} >Expire: {card.exp_date}</p>
                                                                            <p className='text-left my-3' style={{ position: 'relative', top: '15px' }}>CVV: {card.cvv}</p>
                                                                        </div>

                                                                    </div>
                                                                </div>



                                                            </div>



                                                            <div className='row my-2 text-center'>
                                                                <Button className="btn btn-sm col mx-1" variant="info" onClick={() => setIsApplyingVirtualCardModal(false)}>
                                                                    Fund Card
                                                                </Button>

                                                                <Button className="btn col btn-sm mx-1" variant="danger" onClick={() => setIsApplyingVirtualCardModal(false)}>
                                                                    Debit Card
                                                                </Button>

                                                                <Button className="btn col btn-sm mx-1" variant="warning" onClick={() => setIsApplyingVirtualCardModal(false)}>
                                                                    Suspend Card
                                                                </Button>
                                                            </div>

                                                        </>

                                                    ))


                                                ) : (
                                                    <>
                                                        <div className='card-text'>


                                                            We offer you a convenient and secure way to make online payments. With our virtual card service, you can enjoy hassle-free transactions, enhanced security, and complete control over your spending. Request your virtual card today and experience a seamless payment solution for all your online need
                                                        </div>

                                                        {isApplying ? (
                                                            <>


                                                                <Spinner animation="grow" variant="primary"> </Spinner>
                                                                <span>Processing</span></>

                                                        )

                                                            : (
                                                                <button className='btn btn-primary btn-block' onClick={() => setIsApplyingVirtualCardModal(true)}>
                                                                    Request Virtual Card
                                                                </button>
                                                            )}


                                                    </>
                                                )}

                                                {/* </div>

                                                </div> */}
                                            </div>


                                        </div>
                                    )}




                                    {activeTab == 'physical' && (


                                        <div className="row g-3">



                                            <h1>Apply {activeTab} card</h1>
                                            <div className='col-md-6'>
                                                <img className='card-img w-70' src={applyCard} alt='Loan Flier Image' />
                                            </div>


                                            <div className='col-md-6'>

                                                {userDetail.cards.length > 0 && userDetail.cards.some(card => card.card_category_id === 2) ? (
                                                    userDetail.cards.map((card, index) => (
                                                           <div key={index} className="btn-reveal-trigger position-relative rounded-2 overflow-hidden p-4" styl={{ height: '236px' }}>
                                                                <div className="bg-holder"
                                                                    style={{ backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 39.41%, rgba(0, 0, 0, 0.4) 100%), url("static/media/card design.b95d4ce1643a851f1446.png")' }}
                                                                />
                                                                <div className="position-relative h-100 d-flex flex-column justify-content-between">
                                                                    <div className="justify-content-between align-items-center">

                                                                        <div className="z-index-2">
                                                                            <h3 className='text-white text-center'>{card.card_name}</h3>
                                                                        </div>

                                                                        <p className='text-light text-center' style={{ position: 'relative', top: '25px' }}>{maskCardNumber(card.card_number)}</p>
                                                                        <div className='text-white'>
                                                                            <p className='' style={{ position: 'relative', top: '33px' }} >Expire: {card.exp_date}</p>
                                                                            <p className='text-left my-3' style={{ position: 'relative', top: '15px' }}>CVV: {card.cvv}</p>
                                                                        </div>

                                                                    </div>
                                                                </div>



                                                            </div>


                                        
                                                    ))
                                                ) : (
                                                    <>
                                                        <div className='card-text'>
                                                            we believe in offering you the utmost convenience when it comes to managing your finances. With our physical card, you can make secure transactions both online and in-person. Requesting a physical card is a straightforward process that allows you to access your funds and enjoy seamless payment experiences wherever you go
                                                        </div>

                                                        {isApplying ? (
                                                            <>


                                                                <Spinner animation="grow" variant="primary"> </Spinner>
                                                                <span>Processing</span></>

                                                        )

                                                            : (
                                                                <button className='btn btn-primary btn-block' onClick={() => { setIsApplyingPhysicalCardModal(true) }}>
                                                                    Apply Now here



                                                                </button>
                                                            )}


                                                    </>
                                                )}
                                            </div>


                                            

                                        </div>
                                    )}

                                </div>
                            </div>










                        </div>
                    </div>






                    <Modal show={isApplyingVirtualCardModal} onHide={() => { setIsApplyingVirtualCardModal(false) }} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div class="d-flex align-items-center justify-content-between">
                                    <h3 class="mb-0">

                                    </h3>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Card>
                                <Card.Body>



                                </Card.Body>
                            </Card>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setIsApplyingVirtualCardModal(false)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>



                    <Modal show={isApplyingPhysicalCardModal} onHide={() => { setIsApplyingPhysicalCardModal(false) }} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div class="d-flex align-items-center justify-content-between">
                                    <h3 class="mb-0">

                                    </h3>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Card>
                                <Card.Body>
                                    <div className="col-sm-12 col-md-12 gy-6 my-2">

                                        <div className="form-floating">
                                            <Select
                                                id="floatingSelectTask"
                                                value={cardtype}
                                                onChange={(data) => {
                                                    setCardType(data)
                                                }}
                                                options={cardType.map((data) => ({
                                                    value: data.type,
                                                    label: data.name,
                                                }))}
                                                required
                                            />
                                        </div>
                                    </div>


                                    <div className="col-sm-12 col-md-12 gy-6 my-2">

                                        <div className="form-floating">
                                            <textarea
                                                className="form-control"
                                                id="floatingProjectDescription"
                                                placeholder="Leave a comment here"
                                                style={{ height: '50px' }}
                                                value={address}
                                                onChange={(e) => setAddress(e.target.value)}
                                                required
                                            ></textarea>
                                            <label htmlFor="floatingProjectDescription">Delivery Address</label>
                                        </div>
                                    </div>


                                </Card.Body>
                            </Card>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={() => setIsApplyingPhysicalCardModal(false)}>
                                Close
                            </Button>
                            <Button variant="primary"


                                onClick={handlePhysicalCard} disabled={isBuying}>
                                {isBuying ? (
                                    <>
                                        <Spinner animation="border" size="sm" /> Loading...
                                    </>
                                ) : (
                                    "Request Card"
                                )}
                            </Button>
                        </Modal.Footer>
                    </Modal>

                </>
            )

            }


            <ToastContainer />
        </>




    );



}

export default AtmCard;
