import React from 'react';
// import Header from './Header';
import { Link } from 'react-router-dom';
import AuthenticateService from '../auth/AuthenticateService';

function Sidebar() {
  const {token,logout} = AuthenticateService();

  const logoutUser = () => {
      if(token != undefined){
          logout();

      }
  } 
  return (
    <nav style={{ backgroundColor:'' }} className="navbar navbar-vertical navbar-expand-lg bg-gray"   >
      <div className="collapse navbar-collapse" id="navbarVerticalCollapse">
        {/* <!-- scrollbar removed--> */}
        <div className="navbar-vertical-content">
          <ul className="navbar-nav flex-column" style={{ fontSize:'1.0em' }} id="navbarVerticalNav">
            <li className="nav-item">
              <div className="nav-item-wrapper">
                <Link to={'/dashboard'} className="nav-link dropdown-indicator label-1">
                  <div className="d-flex align-items-center">
                    <span className="nav-link-icon">
                      <span data-feather="pie-chart"></span>
                    </span>
                    <span className="nav-link-text">Overview</span>
                  </div>
                </Link>
              </div>
            </li>
            <li className="nav-item">
              <p className="navbar-vertical-label">Quick View</p>
              <hr className="navbar-vertical-line" />
              <Link to={'/dashboard'} className="nav-link  label-1">
                <div className="d-flex align-items-center">
                  <div className="home-icon">
                    <span className="fas fa-user"></span>
                  </div>
                  <span className="nav-link-icon"></span>
                  <span className="nav-link-text">Dashboard</span>
                </div>
              </Link>

              <Link to={'/performance'} className="nav-link  label-1">
                <div className="d-flex align-items-center">
                  <div className="home-icon">
                    <span className="fas fa-bar-chart"></span>
                  </div>
                  <span className="nav-link-icon"></span>
                  <span className="nav-link-text">Performance</span>
                </div>
              </Link>

              <Link to={'/account'} className="nav-link  label-1">
                <div className="d-flex align-items-center">
                  <div className="home-icon">
                    <span className="fas fa-book"></span>
                  </div>
                  <span className="nav-link-icon"></span>
                  <span className="nav-link-text">Account</span>
                </div>
              </Link>

              <Link to={'/atm-card'} className="nav-link  label-1">
                <div className="d-flex align-items-center">
                  <div className="home-icon">
                    <span className="fas fa-credit-card"></span>
                  </div>
                  <span className="nav-link-icon"></span>
                  <span className="nav-link-text">Card</span>
                </div>
              </Link>
            </li>
            
            <li className="nav-item">
              <p className="navbar-vertical-label">All Transactions</p>
              <hr className="navbar-vertical-line" />
              <div className="nav-item-wrapper">
                <Link to="/soft-ussd-pos" className="nav-link label-1">
                  <div className="d-flex align-items-center">
                  <div className="home-icon">
                    <span className="far fa-building"></span>
                  </div>
                  <span className="nav-link-icon"></span>
                  <span className="nav-link-text">Soft USSD POS</span>
                  </div>
                </Link>
              </div>
              <div className="nav-item-wrapper">
                <Link to="/transfer" className="nav-link label-1">
                <div className="d-flex align-items-center">
                  <div className="home-icon">
                    <span className="fas fa-money-bill-wave-alt"></span>
                  </div>
                  <span className="nav-link-icon"></span>
                  <span className="nav-link-text">Bank Transfer</span>
                  </div>
                </Link>
              </div>


              <div className="nav-item-wrapper">
                <Link to="/wallet-transfer" className="nav-link label-1">
                <div className="d-flex align-items-center">
                  <div className="home-icon">
                    <span className="fas fa-money-bill-wave"></span>
                  </div>
                  <span className="nav-link-icon"></span>
                  <span className="nav-link-text">Wallet Transfer</span>
                  </div>
                </Link>
              </div>

             

              <div className="nav-item-wrapper">
                <Link to="/loan" className="nav-link label-1">
                <div className="d-flex align-items-center">
                  <div className="home-icon">
                    <span className="fas fa-monument"></span>
                  </div>
                  <span className="nav-link-icon"></span>
                  <span className="nav-link-text">Loan</span>
                  </div>
                </Link>
              </div>

              <div className="nav-item-wrapper">
                <Link to="/airtime" className="nav-link label-1">
                <div className="d-flex align-items-center">
                  <div className="home-icon">
                    <span className="fas fa-phone"></span>
                  </div>
                  <span className="nav-link-icon"></span>
                  <span className="nav-link-text">Buy Airtime</span>
                  </div>
                </Link>
              </div>
              <div className="nav-item-wrapper">
                <Link to="/data-subscription" className="nav-link label-1">
                <div className="d-flex align-items-center">
                  <div className="home-icon">
                    <span className="fas fa-wifi"></span>
                  </div>
                  <span className="nav-link-icon"></span>
                  <span className="nav-link-text">Buy Data</span>
                  </div>
                </Link>
              </div>
              <div className="nav-item-wrapper">
                <Link to="/pos-terminal" className="nav-link label-1">
                <div className="d-flex align-items-center">
                  <div className="home-icon">
                    <span className="far fa-building"></span>
                  </div>
                  <span className="nav-link-icon"></span>
                  <span className="nav-link-text">POS</span>
                  </div>
                </Link>
              </div>

              <div className="nav-item-wrapper">
                <Link to="/network" className="nav-link label-1">
                <div className="d-flex align-items-center">
                  <div className="home-icon">
                    <span className="fas fa-network-wired"></span>
                  </div>
                  <span className="nav-link-icon"></span>
                  <span className="nav-link-text">Network</span>
                  </div>
                </Link>
              </div>

             


              <div className="nav-item-wrapper">
                <a  href='#' onClick={()=>{
                  logoutUser();
                }} className="nav-link label-1">
                  <div className="d-flex align-items-center">
                  <div className="home-icon">
                    <span className="fa fa-sign-out"></span>
                  </div>
                  <span className="nav-link-icon"></span>
                  <span className="nav-link-text">Logout</span>
                  </div>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="navbar-vertical-footer">
        <button className="btn navbar-vertical-toggle border-0 fw-semi-bold w-100 white-space-nowrap d-flex align-items-center">
          <span className="uil uil-left-arrow-to-left fs-0"></span>
          <span className="uil uil-arrow-from-right fs-0"></span>
          <span className="navbar-vertical-footer-text ms-2">Collapsed View</span>
        </button>
      </div>
    </nav>
  );
}

export default Sidebar;
