import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthenticateService from "../auth/AuthenticateService";
import { Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const Register = (props) => {
  const company = props.company;
  const [showPassword, setShowPassword] = useState(false);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    dob: "",
    pin: "",
    cpassword: "",
    bvn: "",
    company: company != null ? company.company_reference : "",
  });
  const { http, setToken } = AuthenticateService();
  const [isLoging, setIsLoging] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Registration";
  }, []);

  // setFormData({ ...formData, company: company?.company_reference })
  // console.log(formData);

  const [error, setError] = useState("");
  const [logined, setLogined] = useState("");
  const onSubmit = (e) => {
    setError("");
    setFormData({ ...formData, company: company?.company_reference });
    console.log(company?.company_reference);
    console.log(formData);
    e.preventDefault();
    setIsLoging(true);

    const emptyFields = Object.entries(formData)
      .filter(([key, value]) => value === "")
      .map(([key]) => key);

    if (emptyFields.length > 0) {
      console.log(emptyFields.length);
      const errorMessage = `${emptyFields.join(" , ")} field${
        emptyFields.length < 2 ? " is" : "(s) are"
      } required`;
      setError(errorMessage);
      setIsLoging(false);
      return; // Return early if validation fails
    }

    // setError('');

    http
      .post("/users/register", formData)

      .then((res) => {
        if (res.data.status === true) {
          setError("");
          navigate("/");
          toast.success(res.data.message);
          setIsLoging(false);
        } else if (res.status === 0) {
          // navigate('/')

          toast.error(res.data.message);
          setIsLoging(false);
        } else {
          toast.error(res.data.message);
          // setError(res.data.message);
          setIsLoging(false);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setIsLoging(false);
      });
  };

  return (
    <div
      className="container"
      style={{
        backgroundImage: `url("https://safe.wbalite.com/${company?.logo?.replace(
          "public",
          "storage"
        )}")`,
        backgroundRepeat: "repeat",
      }}
    >
      <div className="row flex-center min-vh-100 py-5">
        <div className="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-3 bg-white">
          <a className="d-flex flex-center text-decoration-none" href="/">
            <div className="d-flex align-items-center fw-bolder fs-5 d-inline-block">
              <img
                src={
                  "https://safe.wbalite.com/" +
                  company?.logo?.replace("public", "storage")
                }
                alt=""
                width="100"
              />
            </div>
          </a>
          <div className="text-center mb-3">
            <h3 className="text-1000">{company?.company_name}</h3>
            {/* <h5 className="text-1000"></h5> */}
            <p className="text-700">{company?.address}</p>
            <ToastContainer />
            <h6 className="login-box-msg text-uppercase">
              <span className="error-message text-danger">{error}</span>
              <span className="error-message text-success">{logined}</span>
            </h6>
          </div>

          {company && (
            <form onSubmit={onSubmit}>
              <div className="row mb-3">
                <div className="col-md-6 text-start">
                  <label className="form-label" htmlFor="name">
                    First Name
                  </label>
                  <input
                    onChange={(e) =>
                      setFormData({ ...formData, first_name: e.target.value })
                    }
                    className="form-control"
                    type="text"
                    placeholder="Firstname"
                  />
                </div>
                <div className="col-md-6 text-start">
                  <label className="form-label" htmlFor="name">
                    Last Name
                  </label>
                  <input
                    className="form-control"
                    onChange={(e) =>
                      setFormData({ ...formData, last_name: e.target.value })
                    }
                    type="text"
                    placeholder="Lastname"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6 text-start">
                  <label className="form-label" htmlFor="name">
                    Username
                  </label>
                  <input
                    className="form-control"
                    onChange={(e) =>
                      setFormData({ ...formData, username: e.target.value })
                    }
                    type="username"
                    placeholder="Ali0021"
                  />
                </div>
                <div className="col-md-6 text-start">
                  <label className="form-label" htmlFor="name">
                    Phone Number
                  </label>
                  <input
                    className="form-control"
                    onChange={(e) =>
                      setFormData({ ...formData, phone: e.target.value })
                    }
                    type="tel"
                    placeholder="Phone Number"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6 text-start">
                  <label className="form-label" htmlFor="name">
                    Email
                  </label>
                  <input
                    className="form-control"
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                    type="email"
                    placeholder="Email"
                  />
                </div>
                <div className="col-md-6 text-start">
                  <label className="form-label" htmlFor="name">
                    DOB
                  </label>
                  <input
                    className="form-control"
                    onChange={(e) =>
                      setFormData({ ...formData, dob: e.target.value })
                    }
                    type="date"
                    placeholder="Date Of Birth"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6 text-start">
                  <label className="form-label" htmlFor="name">
                    PIN
                  </label>
                  <input
                    maxLength={4}
                    className="form-control"
                    onChange={(e) =>
                      setFormData({ ...formData, pin: e.target.value })
                    }
                    type="number"
                    placeholder="Transaction PIN"
                  />
                </div>
                {/* <div className="col-md-6 text-start"><label className="form-label" htmlFor="name">BVN</label><input className="form-control" onChange={(e) =>{
     setFormData({ ...formData, bvn: e.target.value });     
     setFormData({ ...formData, company: company?.company_reference })
 }} type="number" placeholder="BVN" /></div> */}

                <div className="col-md-6 text-start">
                  <label className="form-label" htmlFor="name">
                    BVN
                  </label>
                  <input
                    className="form-control"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        bvn: e.target.value,
                        company: company?.company_reference,
                      })
                    }
                    type="number"
                    placeholder="BVN"
                  />
                </div>
              </div>

              <div className="row g-3 mb-3">
                <div className="col-md-6">
                  <label className="form-label" htmlFor="password">
                    Password
                  </label>
                  <input
                    className="form-control form-icon-input"
                    id="password"
                    onChange={(e) =>
                      setFormData({ ...formData, password: e.target.value })
                    }
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                  />

                  <button
                    type="button"
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                      background: "transparent",
                      border: "none",
                      cursor: "pointer",
                    }}
                    className="btn btn-link password-toggle"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </button>
                </div>
                <div className="col-md-6">
                  <label className="form-label" htmlFor="confirmPassword">
                    Confirm Password
                  </label>
                  <input
                    className="form-control form-icon-input"
                    onChange={(e) =>
                      setFormData({ ...formData, cpassword: e.target.value })
                    }
                    id="confirmPassword"
                    type={showPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                  />
                </div>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  id="termsService"
                  type="checkbox"
                />
                <label
                  className="form-label fs--1 text-none"
                  htmlFor="termsService"
                >
                  I accept the <a href="sign-up.html#!">terms </a>and{" "}
                  <a href="#!">privacy policy</a>
                </label>
              </div>
              {isLoging ? (
                <>
                  <Spinner animation="border" size="sm" /> Please wait...
                </>
              ) : (
                <button type="submit" className="btn btn-primary w-100 mb-3">
                  Sign up
                </button>
              )}
              <div className="text-center">
                <Link className="fs--1 fw-bold" to="/">
                  Sign in to an existing account
                </Link>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Register;
