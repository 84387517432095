import React, { useState, useEffect } from 'react'
import AuthenticateService from './AuthenticateService';
import { useNavigate, Link } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faBullhorn, faLightbulb, faMoneyBill, faMoneyBill1, faPhoneFlip, faTabletAndroid, faUser } from '@fortawesome/free-solid-svg-icons';
import PreLoader from './extra/PreLoader';
import defaultDp from './../../assets/img/error/default.png';
import { ToastContainer, toast } from 'react-toastify';

const Profile = () => {

  const navigate = useNavigate();
  const { http, user } = AuthenticateService();
  const [userDetails, setUserDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { token, logout } = AuthenticateService();
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState('');

  const [updateForm, setUpdateForm] = useState({
    profile_pic_url: '',
    signature_url: '',
    other_doc_url: '',
    valid_id_url: '',
    email: '',
    phone: '',
    dob: '',
    pin: '',
    bvn: '',
    nin:'',
    sex:'',
  });


  const [updatePasswordForm, setPasswordUpdateForm] = useState({
   
    confirm_password: '',
    new_password: '',
    current_password:'',
  });

  const getUserDetails = async () => {
    try {
      const response = await http.get('/users/get-details', {
        // params: { page: 1 },
      });
      if (response.status === 200) {
        const data = response.data.data;
        setUserDetails(data);
      } else {
        console.log('Error: Non-200 status code');
      }
    } catch (error) {
      // logout();
      if (error.response.status == 401) {
        logout();
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    document.title = user.first_name + " " + user.last_name + "- Dashboard"; // Set the page title here
    getUserDetails();
  }, []);



  const onUpdatingSubmit = (e) => {

    setError('')
    e.preventDefault()
    setIsUpdating(true)

    const emptyFields = Object.entries(updateForm)
    .filter(([key, value]) => value === '')
    .map(([key]) => key);
  
    if (emptyFields.length > 0) {
      // console.log(emptyFields.length);
      const errorMessage = `${emptyFields.join(' , ')} field${emptyFields.length < 2 ? " is":"(s) are"} required`;
      setError(errorMessage);
      setIsUpdating(false);
      return; // Return early if validation fails
    }
  
    // setError('');

    http.post('/users/update-profile', updateForm)

      .then((res) => {

        if (res.data.status === true) {
          setError('');
          navigate('/')
          toast.success(res.data.message)
          setIsUpdating(false)

        }
        else if (res.status === 0) {
          // navigate('/')
          
          toast.error(res.data.message)
          setIsUpdating(false)

        } else {
          toast.error(res.data.message);
          // setError(res.data.message);
          setIsUpdating(false)
        }
      })
      .catch((error) => {
        
        toast.error(error.response.data.message);
        setIsUpdating(false)
      });

  }


  const onUpdatingPasswordSubmit = (e) => {

    setError('')
    e.preventDefault()
    setIsUpdating(true)

    const emptyFields = Object.entries(updatePasswordForm)
    .filter(([key, value]) => value === '')
    .map(([key]) => key);
  
    if (emptyFields.length > 0) {
      // console.log(emptyFields.length);
      const errorMessage = `${emptyFields.join(' , ')} field${emptyFields.length < 2 ? " is":"(s) are"} required`;
      setError(errorMessage);
      setIsUpdating(false);
      return; // Return early if validation fails
    }

    if(updatePasswordForm.new_password != updatePasswordForm.confirm_password){
     
      toast.warning('Password Not Match');
      setIsUpdating(false);

      return;
    }
  

    http.post('/users/change-password', updatePasswordForm)

      .then((res) => {

        // console.log(res.data.status);
        if (res.data.status === true) {
          setError('');
          // navigate('/')
          // console.log(res.data);
          toast.success(res.data.message)
          setIsUpdating(false)

        }
        else if (res.status === 0) {
          // navigate('/')
          toast.error(res.data.message)
          setIsUpdating(false)

        } else {
          // console.log(res.data.message);
          toast.error(res.data.message);
          // setError(res.data.message);
          setIsUpdating(false)
        }
      })
      .catch((error) => {
        
        // toast.error(error.response.data.message);
        setIsUpdating(false)
      });

  }

  return (
    <>

      {isLoading ? (
        <PreLoader />

      ) : (
        <>
          <Sidebar user={userDetails} />
          <Header user={userDetails} />

          <div className="content">
            <div className="mb-9">
              <div className="row g-6">
                <div className="col-12 col-xl-4">
                  <div className="card cover-image mb-5">
                    <div className="card-header hover-actions-trigger position-relative mb-6" style={{ minHeight: '130px' }}>
                      <div className="bg-holder" style={{ backgroundImage: 'linear-gradient(0deg, #000000 -3%, rgba(0, 0, 0, 0) 83%), url(../../assets/img/generic/59.png)' }}>
                        <input className="d-none" id="upload-cover-image" type="file" />
                        <label className="cover-image-file-input" htmlFor="upload-cover-image" />
                        <div className="hover-actions end-0 bottom-0 pe-1 pb-2 text-white">
                          <span className="fa-solid fa-camera me-2" /></div>
                      </div><input className="d-none" id="upload-porfile-picture" type="file" />
                      <label className="avatar avatar-4xl status-online feed-avatar-profile cursor-pointer" htmlFor="upload-porfile-picture">
                        <img className="rounded-circle img-thumbnail bg-white shadow-sm" src={user && user.profile_pic > 10 ? user.profile_pic : defaultDp} alt="" width={200} /></label>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12">
                          <div className="d-flex flex-wrap mb-2 align-items-center">
                            <h3 className="me-2">{userDetails.first_name} {userDetails.last_name}</h3>
                          </div>
                          <div className="d-flex align-items-center">

                            <div className="d-flex"><span className="fa-solid fa-user-check fs--2 me-2 me-lg-1 me-xl-2" />
                              <h6 className="d-block d-xl-inline-block mb-0">{userDetails.username}

                              </h6>
                            </div>
                          </div>
                        </div>


                      



                        <div className="col-12 mt-5">
                        <form  onSubmit={onUpdatingPasswordSubmit}>
                          <h4 className="mb-4">Change Password</h4>
                          <div className="form-icon-container mb-3">
                            <div className="form-floating"><input className="form-control form-icon-input" onChange={(e) => setPasswordUpdateForm({ ...updatePasswordForm, current_password: e.target.value })} id="oldPassword" type="password" placeholder="Old password" /><label className="text-700 form-icon-label" htmlFor="oldPassword">Old Password</label></div><span className="fa-solid fa-lock text-900 fs--1 form-icon" />
                          </div>
                          <div className="form-icon-container mb-3">
                            <div className="form-floating"><input className="form-control form-icon-input" onChange={(e) => setPasswordUpdateForm({ ...updatePasswordForm, new_password: e.target.value })} id="newPassword" type="password" placeholder="New password" /><label className="text-700 form-icon-label" htmlFor="newPassword">New Password</label></div><span className="fa-solid fa-key text-900 fs--1 form-icon" />
                          </div>
                          <div className="form-icon-container">
                            <div className="form-floating"><input className="form-control form-icon-input" id="newPassword2" type="password"  onChange={(e) => setPasswordUpdateForm({ ...updatePasswordForm, confirm_password: e.target.value })} placeholder="Confirm New password" /><label className="text-700 form-icon-label" htmlFor="newPassword2">Confirm New Password</label></div><span className="fa-solid fa-key text-900 fs--1 form-icon" />
                          </div>
                          <div className="text-end mt-3">
                            <div>
                            {isUpdating ? (

                              <>
                                <Spinner animation="border" size="sm" /> Please wait...
                              </>
                              ) : (
                                <button className="btn btn-phoenix-primary" type='submit'>Change Password</button>
                              )}
                            </div>
                          </div>
                          
                        </form>
                      </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="col-12 col-md-8 col-xl-8">
                  <div className="border-bottom border-300 mb-4">
                    <div className="mb-6">
                      <h4 className="mb-4">Personal Information</h4>
                      <div className="row g-3">
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-icon-container">
                            <div className="form-floating">
                              <input className="form-control form-icon-input" readOnly={true} type="text" value={userDetails.first_name} placeholder="First Name" /><label className="text-700 form-icon-label" htmlFor="firstName">FIRST NAME</label>
                            </div><span className="fa-solid fa-user text-900 fs--1 form-icon" />
                          </div>
                        </div>
                        <div className="col-12  col-md-6 col-xl-6">
                          <div className="form-icon-container">
                            <div className="form-floating"><input className="form-control form-icon-input" readOnly={true} value={userDetails.last_name} type="text" placeholder="Last Name" /><label className="text-700 form-icon-label" htmlFor="lastName">LAST NAME</label></div><span className="fa-solid fa-user text-900 fs--1 form-icon" />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-icon-container">
                            <div className="form-floating"><input className="form-control form-icon-input" readOnly={true} value={userDetails.username} type="username" placeholder="Username" /><label className="text-700 form-icon-label" htmlFor="emailSocial">USERNAME</label></div><span className="fa-solid fa-username text-900 fs--1 form-icon" />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-icon-container">
                            <div className="form-floating"><input className="form-control form-icon-input"  onChange={(e) => setUpdateForm({ ...updateForm, phone: e.target.value })} value={userDetails.phone} type="tel" placeholder="Phone" /><label className="text-700 form-icon-label" htmlFor="phone">ENTER YOUR PHONE</label></div><span className="fa-solid fa-phone text-900 fs--1 form-icon" />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-icon-container">
                            <div className="form-floating">
                            <select className="form-select form-select-sm mt-2"  onChange={(e) => setUpdateForm({ ...updateForm, gender: e.target.value })} value={userDetails.gender}>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>

                            <label className="text-700 form-icon-label" htmlFor="phone">GENDER</label></div><span className="fa-solid fa-gender text-900 fs--1 form-icon" />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-icon-container">
                            <div className="form-floating"><input className="form-control form-icon-input"  onChange={(e) => setUpdateForm({ ...updateForm, email: e.target.value })} value={userDetails.email} type="email" placeholder="Email" /><label className="text-700 form-icon-label" htmlFor="phone">EMAIL ADDRESS</label></div><span className="fa-solid fa-envelope text-900 fs--1 form-icon" />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-icon-container">
                            <div className="form-floating"><input className="form-control form-icon-input" value={userDetails.nin} type="number" placeholder="NIN" /><label className="text-700 form-icon-label" htmlFor="nin">NIN</label></div><span className="fa-solid fa-phone text-900 fs--1 form-icon" />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-icon-container">
                            <div className="form-floating"><input className="form-control form-icon-input"  onChange={(e) => setUpdateForm({ ...updateForm, bvn: e.target.value })} value={userDetails.bvn} type="number" placeholder="BVN" /><label className="text-700 form-icon-label" htmlFor="nin">BVN</label></div><span className="fa-solid fa-phone text-900 fs--1 form-icon" />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-icon-container">
                            <div className="form-floating"><input className="form-control form-icon-input"  onChange={(e) => setUpdateForm({ ...updateForm, dob: e.target.value })} value={userDetails.dob} type="date" placeholder="Date Of Birth" /><label className="text-700 form-icon-label" htmlFor="phone">DATE OF BIRTH</label></div><span className="fa-solid fa-calendar text-900 fs--1 form-icon" />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">

                          <div className="form-icon-container">
                            <div className="form-floating"><input className="form-control form-icon-input"  onChange={(e) => setUpdateForm({ ...updateForm, profile: e.target.value })} value={userDetails.profile_pic} type="url" placeholder="Profile Picture" /><label className="text-700 form-icon-label" htmlFor="phone">PROFILE PICTURE URL</label></div><span className="fa-solid fa-avatar text-900 fs--1 form-icon" />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          
                          <div className="form-icon-container">
                            <div className="form-floating"><input className="form-control form-icon-input"  onChange={(e) => setUpdateForm({ ...updateForm, valid_id_url: e.target.value })} value={userDetails.valid_id_url} type="url" placeholder="ID Card" /><label className="text-700 form-icon-label" htmlFor="phone">VALID ID CARD</label></div><span className="fa-solid fa-avatar text-900 fs--1 form-icon" />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          
                          <div className="form-icon-container">
                            <div className="form-floating"><input className="form-control form-icon-input" value={userDetails.signature_url} type="url" placeholder="Signature" /><label className="text-700 form-icon-label" htmlFor="phone">PROFILE PICTURE URL</label></div><span className="fa-solid fa-avatar text-900 fs--1 form-icon" />
                          </div>
                        </div>


                        <div className="text-end mb-6">
                          <div>
                          {/* {isUpdating ? (

                            <>
                              <Spinner animation="border" size="sm" /> Please wait...
                            </>
                            ) : (
                              <button className="btn btn-phoenix-primary" type='submit'>Save Changes</button>
                            )} */}
                          </div>
                        </div>

                      </div>
                    </div>





                  </div>
                  <ToastContainer />

                </div>

               


              </div>
            </div>

          </div>
        </>
      )
      }
    </>

  );
}

export default Profile;
// 