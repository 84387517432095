import React, { useState, useEffect } from 'react';
import { Form, useNavigate } from 'react-router-dom';
import AuthenticateService from './AuthenticateService';
import Sidebar from './Sidebar';
import Header from './Header';
import { Button, Card, FormGroup, Modal, Spinner } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import NumberFormater from './extra/Formater';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import PreLoader from './extra/PreLoader';

const Loan = () => {

    const { http, user } = AuthenticateService();
    const [userDetail, setUser] = useState([]);
    const [loanList, setLoanList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { token, logout } = AuthenticateService();
    const [isPaying, setIsPaying] = useState(false);

    const [btnColor, setBtnColor] = useState('success'); //['danger', 'success'];

    const [showModal, setShowModal] = useState(false);
    const [checkingEligibity, setcheckingEligibity] = useState(false);
    const [showPaymentHistoryModal, setPaymentHistoryShowModal] = useState(false);
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [showLoanApplicationModal, setShowLoanApplicationModal] = useState(false);
    const [showModalData, setShowModalData] = useState('');
    const [showRepaymentModal, setShowRepaymentModal] = useState(false);
    const [showHistoryProcessingModal, setShowHistoryProcessingModal] = useState(false);
    const [loanValue, setLoanValue] = useState('');
    const [loanRef, setLoanRef] = useState('');
    const [loanAccountType, setLoanAccountType] = useState('');
    const [loanPaymentData, setLoanPaymentData] = useState([]);
    const [loanApplicationData, setLoanApplicationData] = useState([]);



    const handleClosePaymentHistoryModal = () => {
        setPaymentHistoryShowModal(false)

    };


    const handleCloseRepaymentModal = () => {
        setShowRepaymentModal(false);
        setIsPaying(false)

        setLoanValue('');
    };


    const handleCloseApplicationModal = () => {
        setShowLoanApplicationModal(false);
        setIsPaying(false)
        setcheckingEligibity(false)
        setLoanValue('');
    };

    const handlePayLoan = async () => {

        try {
            setIsPaying(true)
            // console.log(loanAccountType);
            const response = await http.post('/loan/pay', {
                loanRef: loanPaymentData.ref,
                amount: loanValue,
                accountType: loanAccountType,

            });


            if (response.data.status === 1) {
                // toast.success(response.data.message);
                // console.log(response);
                Swal.fire({
                    title: response.data.message,
                    text: 'Your payment of ' + loanValue + " from ",
                    icon: 'success',
                    confirmButtonText: 'OK',
                })


                setIsPaying(false)
                setShowRepaymentModal(false)


            } else {
                toast.error(response.data.message);

                // Swal.fire({
                //     title: response.data.message,
                //     text: 'Your payment of ' + loanValue + " from ",
                //     icon: 'error',
                //     confirmButtonText: 'OK',
                // })

            }
        } catch (error) {
          if(error.response){
            if (error.response.status==401){
                logout();
            }
        }else{
            toast.error('Something Went Wrong')
        }
            setIsPaying(false)
            Swal.fire({
                title: 'Failed',
                text: 'Your subscription  of',
                icon: 'error',
                confirmButtonText: 'OK',
            })
            // toast.error('Data request failed, ' + error.response.data.message);
        }

        // Perform actions for loan payment
        // console.log('Loan Value:', loanValue);
        // console.log(loanPaymentData);
        // console.log(loanAccountType);
        // handleCloseModal();
    };


    const handleLoanApplication = async () => {
        setIsPaying(true)
        try {

            const response = await http.post('/loan/send-process-loan', {
                amount: loanValue,
            });

            // console.log(response);
            if (response.status == 200) {
                        Swal.fire({
                        title: "success",//response.message,
                        html: 'Your loan of ' + loanValue + " successfully <br><b>"+response.data.message+"</b>",
                        icon: 'success',
                        confirmButtonText: 'OK',
                    }) 
                setIsPaying(false)
                setcheckingEligibity(false);
                setShowLoanApplicationModal(false)


            } else {
                // toast.error(response.data.message);

                Swal.fire({
                    title: "Failed",
                    text: 'Your loan of ' + loanValue + " failed ",
                    icon: 'error',
                    confirmButtonText: 'OK',
                })

            }
        } catch (error) {
          if(error.response){
            if (error.response.status==401){
                logout();
            }
        }else{
            toast.error('Something Went Wrong')
        }
            setIsPaying(false)
            Swal.fire({
                title: 'Failed',
                text: 'Your loan application  of ' + loanValue + " failed",
                icon: 'error',
                confirmButtonText: 'OK',
            })
        }

    };


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true,
        };
        return date.toLocaleDateString("en-US", options);
    };


    const logoutUser = () => {
        if (token != undefined) {
            logout();
        }
    }

    const getUserDetails = async () => {
        try {
            const response = await http.get('/users/profile', {
                // params: { page: 1 },
            });
            if (response.status === 200) {
                const data = response.data.data;
                // console.log(data);
                setUser(data);
            } else {
                // console.log('Error: Non-200 status code');
                logout()
            }
        } catch (error) {
          if(error.response){
            if (error.response.status==401){
                logout();
            }
        }else{
            toast.error('Something Went Wrong')
        }
        } finally {
            setIsLoading(false);
        }
    };

    const getLoanList = async (type) => {

        try {
            setIsLoading(true)

            const response = await http.get('/loan/list-loan', {
                params: { page: 1 },
            });
            if (response.status === 200) {
                const data = response.data.data;
                setLoanList(data);
            } else {
                // console.log('Error: Non-200 status code');
            }
        } catch (error) {
            // console.error(error);
            // Handle error if needed
          if(error.response){
            if (error.response.status==401){
                logout();
            }
        }else{
            toast.error('Something Went Wrong')
        }
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        document.title = user.first_name + " " + user.last_name + "- Manage Account"; // Set the page title here
        getUserDetails();
        getLoanList();
    }, []);

    const cardStyle = {
        // backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://safe.wbalite.com/assets/images/wiredbanking_logo_outline.png')",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        // opacity: 0.2,
    };


    const chipStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        background: '#808080',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
    };
    const [showBalance, setShowBalance] = useState(false);

    const handleToggleBalance = () => {
        setShowBalance(!showBalance);
    };

    const paymentMethod = ['Cash', 'Wallet', 'Direct Debit'];
    const handleCopyToClipboard = (wallet) => {
        const textarea = document.createElement('textarea');
        textarea.value = wallet;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        toast.info(wallet + 'Copied successfully', {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    const handleViewPaymentHistoryClick = async (ref) => {


        setShowHistoryProcessingModal(true)
        try {
            const response = await http.get('/loan/repayment/' + ref, {
                // params: { page: 1 },
            });

            if (response.status === 200) {

                const data = response.data;

                setLoanRef(ref);
                setPaymentHistory(data);
                setShowHistoryProcessingModal(false)
            } else {

            }
        } catch (error) {
          if(error.response){
            if (error.response.status==401){
                logout();
            }
        }else{
            toast.error('Something Went Wrong')
        }
            // logout();
        } finally {
            // setIsLoading(false);
        }
        setShowHistoryProcessingModal(false);
        setPaymentHistoryShowModal(true);

    };



    const checkEligibilty = async () => {


        setcheckingEligibity(true)
        try {
            const response = await http.get('/loan/eligibility', {
                // params: { page: 1 },
            });

            if (response.status === 200) {
                const data = response.data;
                if (data.status == 0) {
                    Swal.fire({
                        title: response.data.message,
                        icon: 'error',
                        confirmButtonText: 'OK',
                    })
                } else {

                    setLoanApplicationData(data);
                    setShowLoanApplicationModal(true);

                }
            } else {
                Swal.fire({
                    title: response.data.message,
                    icon: 'error',
                    confirmButtonText: 'OK',
                })

                setcheckingEligibity(false)
            }
        } catch (error) {
            // logout();
          if(error.response){
            if (error.response.status==401){
                logout();
            }
        }else{
            toast.error('Something Went Wrong')
        }
            // console.log(error);

        }
        finally {
            // setIsLoading(false);
            // console.log("hi");
            // setcheckingEligibity(false)
        }
        // setShowHistoryProcessingModal(false);
        // setPaymentHistoryShowModal(true);

    };


    const handleViewClick = (loan) => {

        setShowModalData(loan)
        setShowModal(true);
        // console.log(loan);
    };

    const handleLoanRepaymentClick = (loan) => {

        // setShowModalData(loan)
        // console.log(loan);
        setLoanPaymentData(loan)
        setShowRepaymentModal(true);
        setShowModal(false)
        // console.log(loan);
    };


    const handleCloseModal = () => {
        setShowModal(false);
        setcheckingEligibity(false)

        
    };

    const tabs = [
        { id: 'apply', label: 'Apply Loan', data: [], color: 'success' },
        { id: 'active', label: 'Active', data: [], color: 'success' },
        { id: 'pending', label: 'Pending', data: [], color: 'warning' },
        // { id: 'ongoing', label: 'Ongoing', data: [], color: 'primary' },
        { id: 'completed', label: 'Completed', data: [], color: 'info' },
    ];

    // Initialize active tab state
    const [activeTab, setActiveTab] = useState('apply');

    // Function to switch between tabs
    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };

    let filteredLoanList = [];
    // console.log();
    if (loanList && loanList.length > 0) {
        filteredLoanList = loanList.filter((loan) => {
            if (activeTab === 'active') {
                return loan.loan_status_id === 1;
            } else if (activeTab === 'pending') {
                return loan.loan_status_id === 3;
            } else if (activeTab === 'ongoing') {
                return loan.loan_status_id === 4;
            } else if (activeTab === 'completed') {
                return loan.loan_status_id === 10;
            }
            return true;
        });
    }

    // console.log(filteredLoanList);
    return (

        <>
            {isLoading ? (
              <PreLoader/>
              ) : (
                <>
                    <Sidebar />
                    <Header user={user} />


                    <div className="content">
                        <div className="pb-5">
                            <h3 className='text-uppercase'> {activeTab} Loan Overview</h3>





                            <ul className="nav nav-phoenix-pills mb-5 d-sm-non d-xl-flex my-3" id="contactListTab" data-chat-thread-tab="data-chat-thread-tab" role="tablist">
                                {tabs.map((tab, index) => (
                                    <li className="nav-item" role="presentation" key={index}>
                                        <a className={`nav-link cursor-pointer ${activeTab === tab.id ? 'active' : ''}`}
                                            data-bs-toggle="tab"
                                            data-chat-thread-list={tab.label.toLowerCase()}
                                            role="tab"
                                            aria-selected={activeTab === tab.id ? 'active' : ''}
                                            onClick={() => {
                                                handleTabChange(tab.id);
                                                setBtnColor(tab.color);
                                            }}
                                        >
                                            {tab.label}

                                        </a>
                                    </li>
                                ))}
                            </ul>

                            <div className="row g-4" style={{ margin: '0px' }}>
                                <div className="col-12 col-xxl-6">


                                    {activeTab == 'apply' ? (


                                        <div className="row g-3">




                                            <h1>Apply Loan</h1>
                                            <div className='col-md-6'>
                                                <img className='card-img w-70' src='https://www.pngall.com/wp-content/uploads/2017/11/Loan-Download-PNG.png' alt='Loan Flier Image' />
                                            </div>



                                            <div className='col-md-6'>
                                                <div className='card-text'>Apply for our loan and experience the benefits of a high limit,
                                                    the best market rate, and a suitable time frame. Click the <b>'Apply'</b>  button to determine your eligibility for the loan. Remember to apply for an amount that you can comfortably repay, ensuring a good credit score for future reference</div>

                                                {checkingEligibity ? (
                                                    <>


                                                        <Spinner animation="grow" variant="primary"> </Spinner>
                                                        <span>Processing</span></>

                                                )

                                                    : (
                                                        <button className='btn btn-primary btn-block' onClick={checkEligibilty}>
                                                            Apply Now



                                                        </button>
                                                    )}
                                            </div>


                                        </div>
                                    )
                                        : (
                                            <></>
                                        )}

                                </div>
                            </div>


                            {activeTab != "apply" && (
                                <div className="table-responsive mx-n1 px-1 scrollbar">
                                    <table className="table fs--1 mb-0 border-top border-200">
                                        <thead>
                                            {isLoading ? (
                                                // Show the spinner while loading
                                                <Spinner animation="border" className='text-center' variant="info" />
                                            ) : (
                                                <tr>

                                                    <th className="sort white-space-nowrap align-middle" scope="col" data-sort="product">REFERENCE</th>
                                                    <th className="sort align-middle" scope="col" data-sort="customer">AMOUNT</th>
                                                    <th className="sort text-start ps-5 align-middle" scope="col" data-sort="status">STATUS</th>
                                                    <th className="sort text-start ps-5 align-middle" scope="col" data-sort="status">DURATION</th>
                                                    <th className="sort text-start ps-5 align-middle" scope="col" data-sort="status">DATE AND TIME</th>
                                                    <th className="sort text-start ps-5 align-middle" scope="col" data-sort="status">ACTION</th>
                                                </tr>
                                            )}
                                        </thead>
                                        <tbody className="list" id="table-latest-review-body">

                                            {isLoading ? (


                                                <Spinner animation="border" variant="primary" />
                                            )
                                                :
                                                (filteredLoanList && filteredLoanList.length > 0 ? (filteredLoanList.map((loan, index) => (
                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                        <td className="align-middle product white-space-nowrap">{loan.ref}</td>
                                                        <td className="align-middle customer white-space-nowrap">{loan.principal}</td>
                                                        <td className="align-middle text-start ps-5 status">
                                                            <span className={`badge badge-phoenix fs--2  badge-phoenix-${btnColor}`} >
                                                                <span className="badge-label">{loan.loanstatusName}</span>
                                                                <span className="ms-1" data-feather="check" style={{ height: '12.8px', width: '12.8px' }}></span>
                                                            </span>
                                                        </td>

                                                        <td className="align-middle customer white-space-nowrap">{loan.duration} {loan.DurationPeriod}</td>

                                                        <td className="align-middle text-start time white-space-nowrap">
                                                            <div className="hover-hide">
                                                                <h6 className="text-1000 mb-0"> {formatDate(loan.created_at)} </h6>
                                                            </div>
                                                        </td>



                                                        <td className="align-middle white-space-nowrap text-end pe-0 ps-4 btn-reveal-trigger">

                                                            <div className="font-sans-serif btn-reveal-trigger position-static">
                                                                <a href="#" onClick={() => handleViewClick(loan)} title="View Details"> View Details <span className="fas fa-eye fs--2 btn btn-sm" ></span> </a>

                                                    {/* {activeTab == "active"  } */}
                                                    {showHistoryProcessingModal ? (
                                                        <Spinner animation="grow" variant="primary" />
                                                        ) : (
                                                        <>
                                                            {activeTab === "active" && (
                                                            <a href="#" onClick={() => handleViewPaymentHistoryClick(loan.ref)} title="Repayment History">
                                                                Repayment History
                                                                <span className="fas fa-book fs--2 btn btn-sm"></span>
                                                            </a>
                                                            )}
                                                        </>
                                                        )}



                                                            </div>
                                                        </td>




                                                    </tr>
                                                ))) : (
                                                    // Render a message when no loans found
                                                    <p>No loans found.</p>
                                                )
                                                )
                                            }






                                        </tbody>
                                    </table>
                                </div>
                            )}







                        </div>
                    </div>





                    <Modal show={showModal} onHide={handleCloseModal} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div class="d-flex align-items-center justify-content-between">
                                    <h3 class="mb-0">
                                        Ref {showModalData.ref} Summary
                                    </h3>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Card>
                                <Card.Body>
                                    <div class="d-flex justify-content-between mb-2">
                                        <h5 class="text-900 fw-semi-bold">Principal</h5>
                                        <h5 class="text-900 fw-semi-bold">{showModalData.principal}</h5>
                                    </div>

                                    <div class="d-flex justify-content-between mb-2">
                                        <h5 class="text-900 fw-semi-bold">Principal Left</h5>
                                        <h5 class="text-900 fw-semi-bold">{showModalData.principalLeft}</h5>
                                    </div>

                                    <div class="d-flex justify-content-between mb-2">
                                        <h5 class="text-900 fw-semi-bold">Rate</h5>
                                        <h5 class="text-900 fw-semi-bold">{showModalData.rate}%</h5>
                                    </div>

                                    <div class="d-flex justify-content-between mb-2">
                                        <h5 class="text-900 fw-semi-bold">Loan Duration</h5>
                                        <h5 class="text-900 fw-semi-bold">{showModalData.duration} {showModalData.DurationPeriod}</h5>
                                    </div>

                                    <div class="d-flex justify-content-between mb-2">
                                        <h5 class="text-900 fw-semi-bold">Extend Duration</h5>
                                        <h5 class="text-900 fw-semi-bold">{showModalData.ExtendDuration} {showModalData.DurationPeriod}</h5>
                                    </div>
                                    <div class="d-flex justify-content-between mb-2">
                                        <h5 class="text-900 fw-semi-bold">Interest Left</h5>
                                        <h5 class="text-900 fw-semi-bold">{showModalData.InterestLeft}</h5>
                                    </div>

                                    <div class="d-flex justify-content-between mb-2">
                                        <h5 class="text-900 fw-semi-bold">Auto Pay</h5>
                                        <h5 class="text-900 fw-semi-bold">

                                            <div className="form-check form-switch">
                                                <input className="form-check-input" readOnly type="checkbox"
                                                    checked={showModalData.auto_pay} />
                                            </div>

                                        </h5>
                                    </div>

                                    <div class="d-flex justify-content-between mb-2">
                                        <h5 class="text-900 fw-semi-bold">Disbursment In Cash</h5>
                                        <h5 class="text-900 fw-semi-bold">

                                            <div className="form-check form-switch">
                                                <input className="form-check-input" readOnly type="checkbox"
                                                    checked={showModalData.disbursementCash} />
                                            </div>

                                        </h5>
                                    </div>

                                    <div class="d-flex justify-content-between mb-2">
                                        <h5 class="text-900 fw-semi-bold">Management Fee</h5>
                                        <h5 class="text-900 fw-semi-bold">

                                            <div className="form-check form-switch">
                                                <input className="form-check-input" readOnly type="checkbox"
                                                    checked={showModalData.mrgtFee} />
                                            </div>

                                        </h5>
                                    </div>
                                    <div class="d-flex justify-content-between mb-2">
                                        <h5 class="text-900 fw-semi-bold">Start Date</h5>
                                        <h5 class="text-900 fw-semi-bold">
                                            {showModalData.start_at}


                                        </h5>
                                    </div>


                                    <div class="d-flex justify-content-between mb-2">
                                        <h5 class="text-900 fw-semi-bold">Maturity Date</h5>
                                        <h5 class="text-900 fw-semi-bold">
                                            {showModalData.start_at}


                                        </h5>
                                    </div>

                                    <div class="d-flex justify-content-between mb-2">
                                        <h5 class="text-900 fw-semi-bold">Grace Date</h5>
                                        <h5 class="text-900 fw-semi-bold">
                                            {showModalData.grace_at}


                                        </h5>
                                    </div>


                                    <div className="d-flex justify-content-between border-top border-dashed pt-4">
                                        <h4 className="mb-0">Loan Balance :</h4>
                                        <h4 className="mb-0">{showModalData.loanBalance}</h4>
                                    </div>

                                    {showModalData.loanBalance > 0 && activeTab !== "pending" ? (
                                        <button className="btn btn-primary w-100 mt-3" onClick={() => handleLoanRepaymentClick(showModalData)}>Pay Loan</button>
                                    ) : (
                                        <></>
                                    )}


                                </Card.Body>
                            </Card>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>







                    {/* Payment history goes here */}


                    <Modal show={showPaymentHistoryModal} onHide={handleClosePaymentHistoryModal} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div class="d-flex align-items-center justify-content-between">
                                    <h3 class="mb-0">
                                        Ref {loanRef} Payment History
                                    </h3>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Card>
                                <Card.Body>
                                    <div className="table-responsive mx-n1 px-1 scrollbar">
                                        <table className="table fs--1 mb-0 border-top border-200">
                                            <thead>

                                                <tr>

                                                    <th className="sort white-space-nowrap align-middle" scope="col" data-sort="product">REFERENCE</th>
                                                    <th className="sort align-middle" scope="col" data-sort="customer">AMOUNT</th>


                                                    <th className="sort text-start ps-5 align-middle" scope="col" data-sort="status">METHOD</th>
                                                    <th className="sort text-start ps-5 align-middle" scope="col" data-sort="status">BALANCE</th>
                                                    <th className="sort text-start ps-5 align-middle" scope="col" data-sort="status">DATE</th>
                                                </tr>
                                            </thead>





                                            <tbody className="list" id="table-latest-review-body">

                                                {paymentHistory.length > 0 ? (paymentHistory.map((history, index) => (
                                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                        <td className="align-middle product white-space-nowrap">{history.payment_ref}</td>
                                                        <td className="align-middle customer white-space-nowrap">{history.amount}</td>
                                                        <td className="align-middle customer white-space-nowrap">{paymentMethod[history.method - 1]} </td>
                                                        <td className="align-middle customer white-space-nowrap">{history.balance}</td>


                                                        <td className="align-middle customer white-space-nowrap">{formatDate(history.updated_at)} </td>










                                                    </tr>
                                                ))) : (
                                                    // Render a message when no loans found
                                                    <p>No loans found.</p>
                                                )

                                                }






                                            </tbody>
                                        </table>
                                    </div>



                                </Card.Body>
                            </Card>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClosePaymentHistoryModal}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* End payment History */}





                    <Modal show={showRepaymentModal} onHide={handleCloseRepaymentModal} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>Pay Loan</Modal.Title>
                        </Modal.Header>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault(); // Prevent default form submission
                                handlePayLoan();
                            }}
                        >
                            <Modal.Body>


                                <div className="row gx-3 gy-4">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-floating">
                                            <select
                                                className="form-select"
                                                id="floatingSelectTask"
                                                value={loanAccountType}
                                                onChange={(e) => {
                                                    setLoanAccountType(e.target.value);
                                                }}
                                                required
                                            >
                                                {user.wallets && user.wallets.map((wallet, index) => (

                                                    <option key={index}

                                                        value={wallet.account_id}

                                                    >
                                                        {wallet.product_name} ({wallet.wallet_balance})


                                                    </option>
                                                ))}
                                            </select>
                                            <label htmlFor="floatingSelectTask">Pay From</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-floating">
                                            <input
                                                className="form-control"
                                                id="floatingEventInput"
                                                type="number"
                                                maxLength={12}
                                                placeholder="Amount"
                                                value={loanValue}
                                                onChange={(e) => {
                                                    setLoanValue(e.target.value);

                                                }}

                                                required
                                            />
                                            <label htmlFor="floatingEventInput">Amount</label>

                                        </div>
                                    </div>


                                </div>

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseRepaymentModal}>
                                    Cancel
                                </Button>

                                <Button variant="primary" type='submit'>
                                    {isPaying ? (
                                        <>
                                            <Spinner animation="border" size="sm" /> Paying...
                                        </>
                                    ) : (
                                        "Pay Now"
                                    )}
                                </Button>
                            </Modal.Footer>
                        </form>

                    </Modal>


                    <Modal show={showLoanApplicationModal} onHide={handleCloseApplicationModal} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>Apply Now</Modal.Title>
                        </Modal.Header>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault(); // Prevent default form submission
                                handleLoanApplication();
                            }}
                        >
                            <Modal.Body>


                                <div className="row gx-3 gy-4">
                                   
                                    <div className="col-sm-12 col-md-12">
                                        <div className="form-floating">
                                            <input
                                                className="form-control"
                                                id="floatingEventInput"
                                                type="number"
                                                maxLength={12}
                                                placeholder="Amount"
                                                value={loanValue}
                                                max={loanApplicationData.maxamt}
                                                onChange={(e) => {
                                                    setLoanValue(e.target.value);

                                                }}

                                                min={loanApplicationData.minamt}

                                                required
                                            />
                                            <label htmlFor="floatingEventInput">Amount</label>

                                        </div>
                                    </div>


                                </div>

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseApplicationModal}>
                                    Cancel
                                </Button>

                                <Button variant="primary" type='submit'>
                                    {isPaying ? (
                                        <>
                                            <Spinner animation="border" size="sm" /> Paying...
                                        </>
                                    ) : (
                                        "Apply Now"
                                    )}
                                </Button>
                            </Modal.Footer>
                        </form>

                    </Modal>







                </>
            )

            }


            <ToastContainer />
        </>
    );
}

export default Loan;
