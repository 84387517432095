import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button, Spinner } from 'react-bootstrap';
import AuthenticateService from './AuthenticateService';
import Sidebar from './Sidebar';
import Header from './Header';
import TransactionHistory from './extra/dashboard/TransactionHistory';
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import Select from 'react-select'

const TVSubscription = () => {

  const { http, user } = AuthenticateService();
  const [networkProvider, setNetworkProvider] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [amount, setAmount] = useState('');
  const [plan, setPlan] = useState('');
  const [transactionPin, setTransactionPin] = useState('');
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBuying, setIsBuying] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [accountNameVerified, setAccountNameVerified] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [accountName, setAccountName] = useState('');
  const [dataDetails, setDataDetails] = useState([]);
  const { token, logout } = AuthenticateService();

  const dataBundleType = [
    {type:"", name:'Select Network'},
    { type: 'dstv', name: 'DSTV'},
    { type: 'gotv', name: 'GOtv'},
    { type: 'startimes', name: 'Startimes' },
    { type: 'showmax', name: 'Showmax' },
  ];


  // console.log(user.phone);
  const getDataDetails = async (type) => {

    try {
      setIsLoading(true)

      const response = await http.get('/transactions/get-subscriptions', {
        params: { cableTvType: type },
      });
      if (response.status === 200) {
        const data = response.data.data;
        setDataDetails(data);
      } else {
        console.log('Error: Non-200 status code');
      }
    } catch (error) {
      // console.error(error);
      if (error.response.status==401){
        logout();
    }
      // Handle error if needed
    } finally {
      setIsLoading(false);
    }
  };

  const verifyAccountNumber = async (number) => {
    setCardNumber(number);
    try {
        setIsVerifying(true);
        const response = await http.post('/transactions/verify-smart-card', {
          smart_card_number: number,
          cable_tv_type: networkProvider,
        });

        if (response.data.status === true) {
            toast.success(response.data.message);
            // console.log(response.data);
            setAccountNameVerified(true)
            setAccountName(response.data.data.customer_name)
        } else {

            toast.warning(response.data.message);
            setAccountNameVerified(false)
            setAccountName("");
        }

        setIsVerifying(false);
    } catch (error) {
      if (error.response.status==401){
        logout();
    }
        setIsVerifying(false);
        console.log(error);
        setAccountName("");
        Swal.fire({
          title: "Failed",
          text: 'Verification Failed: '+ error.response.data.message,
          icon: 'error',
          confirmButtonText: 'OK',
      })
        // toast.error('Data request failed, ' + error.response.data.message);

        // toast.error('Account verification failed: ');
    }
    // }
};



  const validateForm = () => {
    if (!networkProvider || !cardNumber) {
      toast.error('Please fill in all fields');
      return false;
    }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      setShowPasswordModal(true);
    }
  };

  const handleContinue = async () => {
    if (validateForm()) {

      try {
        setIsBuying(true)
        console.log(plan);
        const response = await http.post('/transactions/subscribe-cable-tv', {
          final_amount: plan.amount,
          subscription_plan: plan.value,
          cable_tv_type: networkProvider,
          transaction_pin: transactionPin,
          phone_number:user.phone,
          smart_card_number:cardNumber,
        });

        if (response.data.status === true) {
          // toast.success(response.data.message);
          Swal.fire({
            title: response.data.message,
            text: 'Your subscription of ' + plan.amount + " to " + accountName + " (" + cardNumber + ")  : " + response.data.message,
            icon: 'success',
            confirmButtonText: 'OK',
        })
          setNetworkProvider('');
          setCardNumber('');
          setAmount('');
          setTransactionPin('');
          setPlan('');
          setShowPasswordModal(false);
          setIsBuying(false)


        } else {


        }
      } catch (error) {
        if (error.response.status==401){
          logout();
      }
        setIsBuying(false)
        Swal.fire({
          title: 'Failed',
          text: 'Your subscription  of ' + plan.amount + " to " + accountName + " (" + cardNumber + ") "+error.response.data.message ,
          icon: 'error',
          confirmButtonText: 'OK',
      })
        // toast.error('Data request failed, ' + error.response.data.message);
      }
    }
  };

  return (
    <>

      <>
        <Sidebar />
        <Header user={user} />

        <div className="content">
          <form className="mb-9" onSubmit={handleSubmit}>
            <div className="row g-5">
              <div className="col-xl-12">
                <h4 className="fs-1 mb-4">Subscription Plan</h4>
                <div className="row gx-3 gy-4">
                  <div className="col-sm-6 col-md-6">
                    <div className="form-floating">
                      <select
                        className="form-select"
                        id="floatingSelectTask"
                        value={networkProvider}
                        onChange={(e) => {
                          setNetworkProvider(e.target.value);
                          getDataDetails(e.target.value); 
                          setAccountName('') ;
                          setAccountNameVerified(false)                        
    
                        }}
                        required
                      >
                        {dataBundleType.map((dataBundle, index) => (
                          <option key={index}
                            selected={dataBundle.default ? true : false}
                            value={dataBundle.type}>
                            {dataBundle.name}


                          </option>
                        ))}
                      </select>
                      <label htmlFor="floatingSelectTask">Subscriptions Plan</label>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-6">
                    <div className="form-floating">
                      <input
                        className="form-control"
                        id="floatingEventInput"
                        type="text"
                        maxLength={12}
                        placeholder="Card Number"
                        value={cardNumber}
                        onChange={(e) => {
                          setCardNumber(e.target.value);
                          if (e.target.value.length > 9) {
                            verifyAccountNumber(e.target.value);
                          
                          }
                        }}
                        disabled={!networkProvider}
                        required
                      />
                      <label htmlFor="floatingEventInput">Card Number</label>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-6">
                    <div className="form-floating">
                      <input
                        className="form-control"
                        id="floatingEventInput"
                        type="text"
                        placeholder="Account Name"
                        value={accountName}
                        onChange={(e) => {
                          setAccountName(e.target.value);
                          
                         
                        }}
                        disabled={!accountNameVerified}

                        />
                        { isVerifying ? (
                        <button
                            type="button"
                            style={{
                                position: 'absolute',
                                top: '50%',
                                right: '10px',
                                transform: 'translateY(-50%)',
                                background: 'transparent',
                                border: 'none',
                                cursor: 'pointer',
                            }}
                            className="btn btn-link password-toggle"
                            
                        >
                            <Spinner animation="border" size="sm" />
                        </button>

                            ): (
                                ""
                            )}
                      <label htmlFor="floatingEventInput">Card Name</label>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-6">
                    {isLoading ? (
                      <div className="d-flex justify-content-center">
                        <Spinner animation="grow" variant="warning"></Spinner>
                        Please Wait
                      </div>
                    ) : (
                      <div className="form-floating">
                       <Select
                        id="floatingSelectTask"
                        value={plan}
                        onChange={setPlan}
                        options={dataDetails.map((data) => ({
                          value: data.variation_code,
                          label: data.name,
                          amount: data.variation_amount
                          
                          
                        }))}
                        required
                      />

                        
                      </div>
                    )}
                  </div>


                

                  <div className="col-sm-6 col-md-6">
                    <div className="d-flex">
                      <button className="btn btn-info px-5 w-100 text-nowrap" type="submit">
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <TransactionHistory transaction_type={13} />

          <Footer />
        </div>

        <Modal show={showPasswordModal} onHide={() => setShowPasswordModal(false)} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Enter Transaction PIN</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-floating">
              <input
                className="form-control"
                type={showPassword ? 'number' : 'password'}
                maxlength={4}

                placeholder="Transaction Pin"
                value={transactionPin}
                onChange={(e) => setTransactionPin(e.target.value)}
                required
              />
               <button
        type="button"
        style={{
          position: 'absolute',
          top: '50%',
          right: '10px',
          transform: 'translateY(-50%)',
          background: 'transparent',
          border: 'none',
          cursor: 'pointer',
        }}
        className="btn btn-link password-toggle"
        onClick={() => setShowPassword(!showPassword)}
      >
        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
      </button>
              <label htmlFor="floatingEventInput">Transaction PIN</label>
            </div>
          </Modal.Body>
          <Modal.Footer>
             <Button variant="secondary"
            
             onClick={() => setShowPasswordModal(false)}>
              Cancel
            </Button>
            {/*<Button variant="primary" onClick={handleContinue}>
              Buy Airtime
            </Button> */}
            <Button variant="primary" 
            
            
            onClick={handleContinue} disabled={isBuying}>
              {isBuying ? (
                <>
                  <Spinner animation="border" size="sm" /> Loading...
                </>
              ) : (
                "Buy Data"
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        <ToastContainer />
      </>
      {/* )} */}
    </>
  );
};

export default TVSubscription;
