import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthenticateService from './AuthenticateService';
import Sidebar from './Sidebar';
import Header from './Header';
import { ToastContainer, toast } from 'react-toastify';
import NumberFormater from './extra/Formater';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import PreLoader from './extra/PreLoader';
import TransactionHistory from './extra/dashboard/TransactionHistory';
import { Modal, Button, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import Swal from 'sweetalert2';


const POS = () => {

    const { http, user } = AuthenticateService();
    const [posLists, setPosLists] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { token, logout } = AuthenticateService();
    const [isBuying, setIsBuying] = useState(false);
    const [withdraw, setWithdraw] = useState(false);
    const [amount, setAmount] = useState('');
    const [terminal, setTerminal] = useState('');

    // console.log(user);

        // if (token != undefined || user===null) {
        //     logout();
        // }

    
    const getPosDetails = async () => {
        try {
            const response = await http.get('/transactions/errandpay/get-pos-terminals', {
                // params: { page: 1 },
            });
            if (response.status === 200) {
                const data = response.data.data;
                // console.log(data);
                setPosLists(data);
            } else {
                // console.log('Error: Non-200 status code');
                logout()
            }
        } catch (error) {
            if(error.response){
          if(error.response){
            if (error.response.status==401){
                logout();
            }
        }else{
            toast.error('Something Went Wrong')
        }
        }else{
            toast.error('Something Went Wrong')
        }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        document.title = user.first_name + " " + user.last_name + "- Manage POS Terminal"; // Set the page title here
        getPosDetails();
    }, []);

    const cardStyle = {
        // backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://safe.wbalite.com/assets/images/wiredbanking_logo_outline.png')",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        // opacity: 0.2,
    };


    const chipStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        background: '#808080',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
    };
    const [showBalance, setShowBalance] = useState(false);

    const handleToggleBalance = () => {
        setShowBalance(!showBalance);
    };

    const handleCopyToClipboard = (wallet) => {
        const textarea = document.createElement('textarea');
        textarea.value = wallet;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        toast.info(wallet + 'Copied successfully', {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    const handleWithdraw = async () => {
        try {
          setIsBuying(true);
          const response = await http.post('/transactions/errandpay/transfer-pos-funds', {
            serial_number: terminal.value,
            transaction_amount: amount,
          });
          if (response.data.status==true) {
            // alert(response.data.message)
            Swal.fire({
              title: response.data.message,
              icon: 'success',
              confirmButtonText: 'OK',
            });
            setIsBuying(false);
            setWithdraw(false);
            setAmount('');
            setTerminal('')
          } else {
            // toast.warning(response.data.message);
            // setWithdraw(false);
          }
          setIsBuying(false);
        } catch (error) {
          if (error.response) {
            if (error.response.status === 401) {
              logout();
            }
          } else {
            toast.error('Something Went Wrong');
          }
          setIsBuying(false);
        }
      };
      



    return (

        <>
            {isLoading ? (
              <PreLoader/>

            ) : (
                <>
                    <Sidebar />
                    <Header user={user} />


                    <div className="content">
                        <div className="pb-5">
                            <h3>POS Terminal</h3>
                          
                            <div className="row g-4" style={{ margin: '0px' }}>
                                <div className="col-12 col-xxl-6">
                                    <div className="row g-3">
                                        {posLists && posLists.map((pos, index) => (


                                            <div className="col-12 col-xl-6 col-xxl-5" key={index}>
                                                <div className="card border border-300 h-100 w-100 overflow-hidden">
                                                    <div className="bg-holder d-block bg-card" style={{ backgroundImage: "url(../assets/img/spot-illustrations/32.png)", backgroundPosition: "top right" }}></div>
                                                    <div className="d-dark-none">
                                                        <div className="bg-holder d-none d-sm-block d-xl-none d-xxl-block bg-card" style={{ backgroundImage: "url(../assets/img/spot-illustrations/21.png)", backgroundPosition: "bottom right", backgroundSize: "auto" }}></div>
                                                    </div>
                                                    <div className="d-light-none">
                                                        <div className="bg-holder d-none d-sm-block d-xl-none d-xxl-block bg-card" style={{ backgroundImage: "url(../assets/img/spot-illustrations/dark_21.png)", backgroundPosition: "bottom right", backgroundSize: "auto" }}></div>
                                                    </div>
                                                    <div className="card-body px-5 position-relative">
                                                        <div className="badge badge-phoenix fs--2 badge-phoenix-warning mb-4">
                                                            <span className="fw-bold">{"POS Wallet"}</span>
                                                            <i className='fa fa-award ms-1'></i>
                                             
                                                        </div>

                                                        {/* <div className="ml-auto badge badge-phoenix fs--2 badge-phoenix-info mb-4"> */}
                                                          <span onClick={()=>{setWithdraw(true);}} style={{ float:'right' }} className='btn btn-sm btn-warning'>
                                                          <span className="fw-bold">{"Transfer Fund"}</span>
                                                            <i className='fa fa-arrow-right ms-1'></i>
                                                            </span>
                                                        {/* </div> */}
                                                        <h5 className="mb-5">Terminal ID: {pos.terminal_code} <i className='ml-3 fa fa-copy' onClick={() => handleCopyToClipboard(pos.terminal_code)}>
                                                                <ToastContainer />
                                                            </i>
                                                        </h5>
                                                        <p className="text-700 fw-semi-bold">Balance <sup>(NGN)</sup> :  <span className='ml-2 font-weight-bold'> {showBalance ? (
                                                                <NumberFormater className="mr-3" num={pos.balance} />
                                                            ) : (
                                                                <span style={{ fontSize: '1.2em' }}>***.**</span>
                                                            )}
                                                        </span>  <FontAwesomeIcon onClick={handleToggleBalance} icon={showBalance ? faEye : faEyeSlash} className="ml-3" />                                                       
                                                        </p>
                                                   </div>

                                                </div>
                                            </div>
                                        ))}




                                    </div>
                                </div>
                            </div>

                            <Modal show={withdraw} onHide={() => setWithdraw(false)} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>
                            <div class="alert alert-outline-warning d-flex align-items-center" role="alert">
                            <span class="fas fa-info-circle text-warning fs-3"></span>
                                    You are about to transfer your POS wallet balance to your main wallet
                            </div>
                                
                                </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <div className="form-floating">
                            <Select
                                id="floatingSelectTask"
                                value={terminal}
                                onChange={setTerminal}

                                options={posLists.map((bank) => ({
                                    value: bank.terminal_code,
                                    label: bank.terminal_code +" ("+ bank.balance +")",
                                }))}
                                required
                            />
                        </div>
                            <div className="form-floating">
                                <input
                                    className="form-control"
                                    type='number'

                                    placeholder="Amount"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    required
                                />




                                <label htmlFor="floatingEventInput">Withdraw Amount</label>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary"

                                onClick={() => setWithdraw(false)}>
                                Cancel
                            </Button>

                            <Button variant="primary"


                                onClick={handleWithdraw} disabled={isBuying}>
                                {isBuying ? (
                                    <>
                                        <Spinner animation="border" size="sm" /> Withdrawing...
                                    </>
                                ) : (
                                    "Withdraw Now"
                                )}
                            </Button>
                        </Modal.Footer>
                    </Modal>



                        </div>


                        {/* {Array.from({ length: 84 - 63 + 1 }, (_, index) => 63 + index)} */}
                        <TransactionHistory transaction_type={ Array.from({ length: 84 - 63 + 1 }, (_, index) => 63 + index) } title="POS Terminal Transactions"/>


                    </div>






                </>
            )

            }
        </>
    );
}

export default POS;
