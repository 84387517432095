import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button, Spinner } from 'react-bootstrap';
import AuthenticateService from './AuthenticateService';
import axios from 'axios';
import Sidebar from './Sidebar';
import Header from './Header';
import TransactionHistory from './extra/dashboard/TransactionHistory';
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import PreLoader from './extra/PreLoader';

const Airtime = () => {

  const { http, user } = AuthenticateService();
  const [networkProvider, setNetworkProvider] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [amount, setAmount] = useState('');
  const [transactionPin, setTransactionPin] = useState('');
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBuying, setIsBuying] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const defaultAmount = [50, 100, 200, 300, 500, 750];
  const { token, logout } = AuthenticateService();


  useEffect(() => {
    document.title = user.first_name + " " + user.last_name + "- Airtime"; // Set the page title here
  }, []);

  const validateForm = () => {
    if (!networkProvider || !phoneNumber) {
      toast.error('Please fill in all fields');
      return false;
    }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      setShowPasswordModal(true);
    }
  };


  const handleContinue = async () => {
    if (validateForm()) {
      try {

        setIsBuying(true)
        const response = await http.post('/transactions/buy-airtime', {
          final_amount: amount,
          transaction_type: 11,
          network_provider: networkProvider,
          phone_number: phoneNumber,
          transaction_pin: transactionPin,
          description: networkProvider + " " + phoneNumber + " " + amount,
        }).then((res) => {

          if (res.data.status === true) {
            // toast.success(res.data.message);
            setNetworkProvider('');
            setPhoneNumber('');
            setAmount('');
            setTransactionPin('');
            setShowPasswordModal(false);
            setIsBuying(false)
            Swal.fire({
              title: 'Successful',
              text: res.data.message,
              icon: 'success',
              confirmButtonText: 'OK',
          })

          } else {
            // setError(res.data.message);
          }
        })
          .catch((error) => {
            setIsBuying(false)
            Swal.fire({
              title: 'Failed',
              text: error.response.data.message,
              icon: 'error',
              confirmButtonText: 'OK',
          })

            // toast.error('Airtime request failed, ' + error.response.data.message);

          });
      } catch (error) {
        setIsBuying(false)
        if (error.response.status==401){
          logout();
        }
        Swal.fire({
          title: 'Failed',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'OK',
      })

        // toast.error('Airtime request failed, ' + error.response.data.message);

      }
    }
  };


  return (
    <>
      {isLoading ? (
        <PreLoader/>

      ) : (
        <>
          <Sidebar />
          <Header user={user} />

          <div className="content">
            <form className="mb-9" onSubmit={handleSubmit}>
              <div className="row g-5">
                <div className="col-xl-8">
                  <h4 className="fs-1 mb-4">Airtime Subscription</h4>
                  <div className="row gx-3 gy-4">
                    <div className="col-sm-6 col-md-6">
                      <div className="form-floating">
                        <select
                          className="form-select"
                          id="floatingSelectTask"
                          value={networkProvider}
                          onChange={(e) => setNetworkProvider(e.target.value)}
                          required
                        >
                          <option value=""></option>
                          <option value="mtn">MTN</option>
                          <option value="glo">Glo</option>
                          <option value="airtel">Airtel</option>
                          <option value="etisalat">Etisalat</option>
                        </select>
                        <label htmlFor="floatingSelectTask">Network Provider</label>
                      </div>
                    </div>

                    <div className="col-sm-6 col-md-6">
                      <div className="form-floating">
                        <input
                          className="form-control"
                          id="floatingEventInput"
                          type="text"
                          placeholder="Phone Number"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          required
                        />
                        <label htmlFor="floatingEventInput">Phone Number</label>
                      </div>
                    </div>

                    <div className="col-sm-6 col-md-6">
                      <div className="row">
                        {defaultAmount.map((amt, index) => (
                          <div key={index} className="col-4">
                            <span
                              onClick={() => setAmount(amt)}
                              className={`btn btn-sm  rounded-pill w-100 me-3 mb-2  fs--1 fs-sm-0 ${amount === amt ? 'btn-warning' : 'btn-outline-warning'}`}
                            >
                              {amt}

                            </span>
                          </div>
                        ))}
                      </div>
                    </div>




                    <div className="col-sm-6 col-md-6">
                      <div className="form-floating">
                        <input
                          className="form-control"
                          id="floatingEventInput"
                          type="number"
                          step={2}
                          placeholder="Amount"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          required
                        />
                        <label htmlFor="floatingEventInput">Amount</label>
                      </div>
                    </div>



                    <div className="col-12 col-sm-auto col-xl-4">
                      <div className="d-flex">
                        <button className="btn btn-primary px-5 w-100 text-nowrap" type="submit">
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <TransactionHistory transaction_type={11} title="Airtime Transactions"/>



            <Footer />
          </div>


          <Modal show={showPasswordModal} onHide={() => setShowPasswordModal(false)} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>Enter Transaction PIN</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-floating">
                <input
                  className="form-control"
                  type={showPassword ? 'number' : 'password'}
                  maxlength={4}

                  placeholder="Transaction Pin"
                  value={transactionPin}
                  onChange={(e) => setTransactionPin(e.target.value)}
                  required
                />
                <button
                  type="button"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    right: '10px',
                    transform: 'translateY(-50%)',
                    background: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                  className="btn btn-link password-toggle"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </button>
                <label htmlFor="floatingEventInput">Transaction PIN</label>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary"

                onClick={() => setShowPasswordModal(false)}>
                Cancel
              </Button>
            
              <Button variant="primary"


                onClick={handleContinue} disabled={isBuying}>
                {isBuying ? (
                  <>
                    <Spinner animation="border" size="sm" /> Loading...
                  </>
                ) : (
                  "Buy Airtime"
                )}
              </Button>
            </Modal.Footer>
          </Modal>

          <ToastContainer />
        </>
      )

      }
    </>
  );
}

export default Airtime;
