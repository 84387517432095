import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button, Spinner } from 'react-bootstrap';
import AuthenticateService from './AuthenticateService';
import Sidebar from './Sidebar';
import Header from './Header';
import TransactionHistory from './extra/dashboard/TransactionHistory';
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select'
import Swal from 'sweetalert2';

const DataSubscription = () => {

  const { http, user } = AuthenticateService();
  const [networkProvider, setNetworkProvider] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  // const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');
  const [plan, setPlan] = useState('');
  const [transactionPin, setTransactionPin] = useState('');
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBuying, setIsBuying] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { token, logout } = AuthenticateService();

  const [dataDetails, setDataDetails] = useState([]);


  const dataBundleType = [
    {type:"", name:'Select Network'},
    { type: 'mtn-data', name: 'MTN'},
    { type: 'glo-data', name: 'Glo'},
    { type: 'etisalat-data', name: 'Etisalat' },
    { type: 'airtel-data', name: 'Airtel' },
  ];

  

  const getDataDetails = async (type) => {

    try {
      setIsLoading(true)

      const response = await http.get('/transactions/get-data-bundles/' + type, {
        params: { page: 1 },
      });
      if (response.status === 200) {
        const data = response.data.data;
        setDataDetails(data);
      } else {
        console.log('Error: Non-200 status code');
      }
    } catch (error) {
      // console.error(error);
      if (error.response.status==401){
        logout();
    }
      // Handle error if needed
    } finally {
      setIsLoading(false);
    }
  };


  const handleSelectPlan = (selectedPlan) => {
    // console.log(selectedPlan);
    setPlan(selectedPlan);
    // setAccountNumber('');
    // setAccountName('')


};

useEffect(() => {
  document.title = user.first_name + " " + user.last_name + "- Data Subscription"; // Set the page title here
}, []);


  const validateForm = () => {
    if (!networkProvider || !phoneNumber) {
      toast.error('Please fill in all fields');
      return false;
    }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      setShowPasswordModal(true);
    }
  };

  const handleContinue = async () => {
    if (validateForm()) {

      try {
        setIsBuying(true)
        const response = await http.post('/transactions/buy-data-bundle', {
          final_amount: plan.amount,
          transaction_type: 12,
          network_provider: networkProvider,
          phone_number: phoneNumber,
          transaction_pin: transactionPin,
          description: networkProvider + " " + phoneNumber + " " + amount,
          data_plan: plan.value,
        });
        if (response.data.status === true) {
          // toast.success(response.data.message);

          setNetworkProvider('');
          setPhoneNumber('');
          setAmount('');
          setTransactionPin('');
          setPlan('');
          setShowPasswordModal(false);
          setIsBuying(false);
          Swal.fire({
            title: 'Successful',
            text: response.data.message,
            icon: 'success',
            confirmButtonText: 'OK',
        })
          


        } else {

          Swal.fire({
            title: 'Failed',
            text: response.data.message,
            icon: 'error',
            confirmButtonText: 'OK',
        })
        }
      } catch (error) {
        setIsBuying(false)
        if (error.response.status==401){
          logout();
      }
        Swal.fire({
          title: 'Failed',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'OK',
      })
        // toast.error('Data request failed, ' + error.response.data.message);
      }
    }
  };

  return (
    <>

      <>
        <Sidebar />
        <Header user={user} />

        <div className="content">
          <form className="mb-9" onSubmit={handleSubmit}>
            <div className="row g-5">
              <div className="col-xl-12">
                <h4 className="fs-1 mb-4">Data Subscription</h4>
                <div className="row gx-3 gy-4">
                  <div className="col-sm-6 col-md-6">
                    <div className="form-floating">
                      <select
                        className="form-select"
                        id="floatingSelectTask"
                        value={networkProvider}
                        onChange={(e) => {
                          setNetworkProvider(e.target.value);
                          getDataDetails(e.target.value);
                          // setPhoneNumber('')
                          
                        }}
                        required
                      >
                        {dataBundleType.map((dataBundle, index) => (
                          <option key={index}
                            selected={dataBundle.default ? true : false}
                            value={dataBundle.type}>
                            {dataBundle.name}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="floatingSelectTask">Network Provider</label>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-6">
                    <div className="form-floating">
                      <input
                        className="form-control"
                        id="floatingEventInput"
                        type="text"
                        placeholder="Phone Number"
                        value={phoneNumber}
                        onChange={(e) => {
                          setPhoneNumber(e.target.value);
                          
                         
                        }}
                        required
                      />
                      <label htmlFor="floatingEventInput">Phone Number</label>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-6">
                    {isLoading ? (
                      <div className="d-flex justify-content-center">
                        <Spinner animation="grow" variant="warning"></Spinner>
                        Please Wait
                      </div>
                    ) : (
                      <div className="form-floating">

                          <Select
                            id="floatingSelectTask"
                            value={plan}
                            onChange={handleSelectPlan}

                            options={dataDetails.map((data) => ({
                                value: data.variation_code,
                                label: data.name,
                                amount:data.variation_amount,
                            }))}
                            required
                        />
                        {/* <select
                          className="form-select"
                          id="floatingSelectTask"
                          value={plan}
                          onChange={(e) => {
                            const selectedData = dataDetails.find((data) => data.variation_amount === e.target.value);
                            if (selectedData) {
                              setAmount(selectedData.variation_amount);
                              setPlan(selectedData.variation_code);
                              // console.log(selectedData);
                            }
                          }}
                          required
                        >
                          {dataDetails.map((data) => (
                            <option key={data.variation_code} value={data.code}>
                              {data.name}
                            </option>
                          ))}
                        </select> */}
                        {/* <label htmlFor="floatingSelectTask">Select Plan</label> */}
                      </div>
                    )}
                  </div>


                

                  <div className="col-sm-6 col-md-6">
                    <div className="d-flex">
                      <button className="btn btn-info px-5 w-100 text-nowrap" type="submit">
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <TransactionHistory transaction_type={12} title="Data Subscription"/>

          <Footer />
        </div>

        <Modal show={showPasswordModal} onHide={() => setShowPasswordModal(false)} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Enter Transaction PIN</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-floating">
              <input
                className="form-control"
                type={showPassword ? 'number' : 'password'}
                maxlength={4}

                placeholder="Transaction Pin"
                value={transactionPin}
                onChange={(e) => setTransactionPin(e.target.value)}
                required
              />
               <button
        type="button"
        style={{
          position: 'absolute',
          top: '50%',
          right: '10px',
          transform: 'translateY(-50%)',
          background: 'transparent',
          border: 'none',
          cursor: 'pointer',
        }}
        className="btn btn-link password-toggle"
        onClick={() => setShowPassword(!showPassword)}
      >
        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
      </button>
              <label htmlFor="floatingEventInput">Transaction PIN</label>
            </div>
          </Modal.Body>
          <Modal.Footer>
             <Button variant="secondary"
            
             onClick={() => setShowPasswordModal(false)}>
              Cancel
            </Button>
            {/*<Button variant="primary" onClick={handleContinue}>
              Buy Airtime
            </Button> */}
            <Button variant="primary" 
            
            
            onClick={handleContinue} disabled={isBuying}>
              {isBuying ? (
                <>
                  <Spinner animation="border" size="sm" /> Loading...
                </>
              ) : (
                "Buy Data"
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        <ToastContainer />
      </>
      {/* )} */}
    </>
  );
};

export default DataSubscription;
