import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthenticateService from '../auth/AuthenticateService';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [cpassword, setCPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [isLogging, setIsLogging] = useState(false);
  const phoneNumber = sessionStorage.getItem('resetPhoneNumber');
  const navigate = useNavigate();
  const { http, setToken } = AuthenticateService();


  if(phoneNumber===null){
    navigate('/');
    sessionStorage.clear();

}


  const handlePasswordRequest = (e) => {
    e.preventDefault();
    if (password !== cpassword) {
      setError('Passwords do not match');
      setIsLogging(false);
      return;
    }
    setIsLogging(true);
    http.post('/users/reset-password', {
      phone_number: phoneNumber,
      otp_code:otp,
      password:password,
    })

      .then((res) => {
        // console.log(res);

        if (res.status === 200) {
          setError('');
          toast.success(res.data.message);
          // sessionStorage.setItem('resetPhoneNumber', phoneNumber);
          navigate('/');
          sessionStorage.clear();


        } else {
          setError(res.message);
          setIsLogging(false)
        }
      })
      .catch((error) => {

        // setError('Failed to resend ')users/resend-otp
        // console.log(error.response.data.message);
        toast.error('Resend Failed : ' + error.response.data.message);
        setIsLogging(false)

      });
  }

  return (
    <div className="container">
      <div className="row flex-center min-vh-100 py-5">
        <div className="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-3">
          <a className="d-flex flex-center text-decoration-none mb-4" href="/">
            <div className="d-flex align-items-center fw-bolder fs-5 d-inline-block p-4">
              {/* <img src="https://safe.wbalite.com/assets/images/wiredbanking_logo_outline.png" alt="wba" width="100" /> */}
            </div>
          </a>
          <div className="text-center mb-6">
            <h4 className="text-1000">Reset new password</h4>
            <p className="text-700">Type your new password</p>
            <h6 className="login-box-msg text-uppercase">
                <span className="error-message text-danger">{error}</span>
              </h6>

            <form className="mt-5" onSubmit={handlePasswordRequest}>
              <input
                className="form-control mb-2"
                type="number"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Received OTP"
              />
              <input
                className="form-control mb-2"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="Type new password"
              />
              <input
                className="form-control mb-4"
                value={cpassword}
                onChange={(e) => setCPassword(e.target.value)}
                type="password"
                placeholder="Confirm new password"
              />
              <button className="btn btn-primary w-100" type="submit">
                {isLogging ? 'Processing...' : 'Set Password'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
