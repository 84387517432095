import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthenticateService from './AuthenticateService';
import Sidebar from './Sidebar';
import Header from './Header';
import { Card, Spinner } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import NumberFormater from './extra/Formater';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import PreLoader from './extra/PreLoader';

const Account = () => {

    const { http, user } = AuthenticateService();
    const [userDetail, setUserDetail] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { token, logout } = AuthenticateService();
    const logoutUser = () => {
        if (token != undefined) {
            logout();
        }
    }
    const getUserDetails = async () => {
        try {
            const response = await http.get('/users/get-details', {
                // params: { page: 1 },
            });
            if (response.status === 200) {
                const data = response.data.data;
                // console.log(data);
                setUserDetail(data);
            } else {
                // console.log('Error: Non-200 status code');
                logout()
            }
        } catch (error) {
            // logout();
          if(error.response){
            if (error.response.status==401){
                logout();
            }
        }else{
            toast.error('Something Went Wrong')
        }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        document.title = user.first_name + " " + user.last_name + "- Manage Account"; // Set the page title here
        getUserDetails();
    }, []);

    const cardStyle = {
        // backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://safe.wbalite.com/assets/images/wiredbanking_logo_outline.png')",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        // opacity: 0.2,
    };


    const chipStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        background: '#808080',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
    };
    const [showBalance, setShowBalance] = useState(false);

    const handleToggleBalance = () => {
        setShowBalance(!showBalance);
    };

    const handleCopyToClipboard = (wallet) => {
        const textarea = document.createElement('textarea');
        textarea.value = wallet;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        toast.info(wallet + 'Copied successfully', {
            position: toast.POSITION.TOP_RIGHT
        });
    };


    // console.log(user);


    return (

        <>
            {isLoading ? (
              <PreLoader/>

            ) : (
                <>
                    <Sidebar />
                    <Header user={user} />


                    <div className="content">
                        <div className="pb-5">
                            <h3>Account Overview</h3>




                            <div className="row g-4" style={{ margin: '0px' }}>
                                <div className="col-12 col-xxl-6">
                                    <div className="row g-3">
                                        {userDetail.wallets && userDetail.wallets.map((wallet, index) => (


                                            <div className="col-12 col-xl-6 col-xxl-5" key={index}>
                                                <div className="card border border-300 h-100 w-100 overflow-hidden">
                                                    <div className="bg-holder d-block bg-card" style={{ backgroundImage: "url(../assets/img/spot-illustrations/32.png)", backgroundPosition: "top right" }}></div>
                                                    <div className="d-dark-none">
                                                        <div className="bg-holder d-none d-sm-block d-xl-none d-xxl-block bg-card" style={{ backgroundImage: "url(../assets/img/spot-illustrations/21.png)", backgroundPosition: "bottom right", backgroundSize: "auto" }}></div>
                                                    </div>
                                                    <div className="d-light-none">
                                                        <div className="bg-holder d-none d-sm-block d-xl-none d-xxl-block bg-card" style={{ backgroundImage: "url(../assets/img/spot-illustrations/dark_21.png)", backgroundPosition: "bottom right", backgroundSize: "auto" }}></div>
                                                    </div>
                                                    <div className="card-body px-5 position-relative">
                                                        <div className="badge badge-phoenix fs--2 badge-phoenix-warning mb-4">
                                                            <span className="fw-bold">{wallet.product_name}</span>
                                                            <svg className="svg-inline--fa fa-award ms-1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="award" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg="">
                                                                <path fill="currentColor" d="M288 358.3c13.98-8.088 17.53-30.04 28.88-41.39c11.35-11.35 33.3-14.88 41.39-28.87c7.98-13.79 .1658-34.54 4.373-50.29C366.7 222.5 383.1 208.5 383.1 192c0-16.5-17.27-30.52-21.34-45.73c-4.207-15.75 3.612-36.5-4.365-50.29c-8.086-13.98-30.03-17.52-41.38-28.87c-11.35-11.35-14.89-33.3-28.87-41.39c-13.79-7.979-34.54-.1637-50.29-4.375C222.5 17.27 208.5 0 192 0C175.5 0 161.5 17.27 146.3 21.34C130.5 25.54 109.8 17.73 95.98 25.7C82 33.79 78.46 55.74 67.11 67.08C55.77 78.43 33.81 81.97 25.72 95.95C17.74 109.7 25.56 130.5 21.35 146.2C17.27 161.5 .0008 175.5 .0008 192c0 16.5 17.27 30.52 21.34 45.73c4.207 15.75-3.615 36.5 4.361 50.29C33.8 302 55.74 305.5 67.08 316.9c11.35 11.35 14.89 33.3 28.88 41.4c13.79 7.979 34.53 .1582 50.28 4.369C161.5 366.7 175.5 384 192 384c16.5 0 30.52-17.27 45.74-21.34C253.5 358.5 274.2 366.3 288 358.3zM112 192c0-44.27 35.81-80 80-80s80 35.73 80 80c0 44.17-35.81 80-80 80S112 236.2 112 192zM1.719 433.2c-3.25 8.188-1.781 17.48 3.875 24.25c5.656 6.75 14.53 9.898 23.12 8.148l45.19-9.035l21.43 42.27C99.46 507 107.6 512 116.7 512c.3438 0 .6641-.0117 1.008-.0273c9.5-.375 17.65-6.082 21.24-14.88l33.58-82.08c-53.71-4.639-102-28.12-138.2-63.95L1.719 433.2zM349.6 351.1c-36.15 35.83-84.45 59.31-138.2 63.95l33.58 82.08c3.594 8.797 11.74 14.5 21.24 14.88C266.6 511.1 266.1 512 267.3 512c9.094 0 17.23-4.973 21.35-13.14l21.43-42.28l45.19 9.035c8.594 1.75 17.47-1.398 23.12-8.148c5.656-6.766 7.125-16.06 3.875-24.25L349.6 351.1z"></path>
                                                            </svg>
                                                        </div>
                                                        <h3 className="mb-5">{wallet.bank}</h3>
                                                        <p className="text-700 fw-semi-bold">Balance <sup>(NGN)</sup> :  <span className='ml-2 font-weight-bold'>
                                                            {showBalance ? (
                                                                <NumberFormater className="mr-3" num={wallet.wallet_balance} />
                                                            ) : (
                                                                <span style={{ fontSize: '1.2em' }}>*** **</span>
                                                            )}
                                                        </span> <FontAwesomeIcon onClick={handleToggleBalance} icon={showBalance ? faEye : faEyeSlash} className="ml-3" />
                                                       
                                                        </p>


                                                        <p className="text-700 fw-semi-bold">Account Number :                      <span>
                                                            {wallet.account_number} <i className='ml-3 fa fa-copy' onClick={() => handleCopyToClipboard(wallet.account_number)}>
                                                                <ToastContainer />
                                                            </i>

                                                        </span>
                                                        </p>








                                                    </div>

                                                </div>
                                            </div>
                                        ))}




                                    </div>
                                </div>
                            </div>





                        </div>
                    </div>






                </>
            )

            }
        </>
    );
}

export default Account;
