import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthenticateService from './AuthenticateService';
import Sidebar from './Sidebar';
import Header from './Header';
import { Card, Spinner } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import NumberFormater from './extra/Formater';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import PreLoader from './extra/PreLoader';
import TransactionHistory from './extra/dashboard/TransactionHistory';
import DataTable from 'react-data-table-component';

const Network = () => {

    const { http, user } = AuthenticateService();
    const [networkLists, setNetworkLists] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { token, logout } = AuthenticateService();
    const logoutUser = () => {
        if (token != undefined) {
            logout();
        }
    }

    
    const getPosDetails = async () => {
        try {
            const response = await http.get('/get-banks', {
                // params: { page: 1 },
            });
            if (response.status === 200) {
                const data = response.data.data;
                // console.log(data);
                setNetworkLists(data);
            } else {
                // console.log('Error: Non-200 status code');
                logout()
            }
        } catch (error) {
            if(error.response){
          if(error.response){
            if (error.response.status==401){
                logout();
            }
        }else{
            toast.error('Something Went Wrong')
        }
        }else{
            toast.error('Something Went Wrong')
        }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        document.title = user.first_name + " " + user.last_name + "- Manage POS Terminal"; // Set the page title here
        getPosDetails();
    }, []);

 

console.log(networkLists);
 
    const dataTableTitle = [
        { name: 'BANK', selector: row => row.bank_name, sortable: true,  classNames: ['align-middle', 'product','white-space-nowrap'],headerClassName:['sort', 'white-space-nowrap', 'align-middle']},
        { name: 'Master Card', selector: row => row.bank_code, sortable: true, },
        { name: 'Verve', selector: row => row.short_code, sortable: true, },
        { name: 'Visa', selector: row => row.short_code, sortable: true, },
        { name: 'Deposit', selector: row => row.short_code, sortable: true, },
        { name: 'Transfer', selector: row => row.short_code, sortable: true, },
        { name: 'Bills', selector: row => row.short_code, sortable: true, },

        
    ];

    // console.log(posLists);



    return (

        <>
            {isLoading ? (
              <PreLoader/>

            ) : (
                <>
                    <Sidebar />
                    <Header user={user} />


                    <div className="content">
                        <div className="pb-5">
                            <h3>Networks</h3>
       


                            <div className="table-responsive mx-n1 px-1 scrollbar">
                    <table className="table fs--1 mb-0 border-top border-200">
                        <thead>
                          
                            <tr>

                                <th className="sort white-space-nowrap align-middle" scope="col" data-sort="product">BANK</th>
                                <th className="sort align-middle" scope="col" data-sort="customer">MASTER CARD</th>
                                <th className="sort text-start ps-5 align-middle" scope="col" data-sort="status">VERVE</th>
                                <th className="sort text-start ps-5 align-middle" scope="col" data-sort="status">VISA</th>
                                <th className="sort text-start ps-5 align-middle" scope="col" data-sort="status">DEPOSIT</th>
                                <th className="sort text-start ps-5 align-middle" scope="col" data-sort="status">TRANSFER</th>
                                <th className="sort text-start ps-5 align-middle" scope="col" data-sort="status">BILL</th>
                            </tr>
                        </thead>
                        <tbody className="list" id="table-latest-review-body">

                            {isLoading ? (

                                <Spinner animation="border" variant="primary" />
                            )
                                :
                                (networkLists.length > 0 ? (networkLists.map((bank, index) => (
                                    <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                    <td className="align-middle product white-space-nowrap">{bank.bank_name}</td>
                                    <td className="fs--1 align-middle ps-0 py-3">
                                      <div className="form-check mb-0 fs-0">
                                        <input className="form-check-input" type="checkbox" checked={true} data-bulk-select-row="" />
                                      </div>
                                    </td>
                                    <td className="fs--1 align-middle ps-0 py-3">
                                      <div className="form-check mb-0 fs-0">
                                        <input className="form-check-input" type="checkbox"  checked={true} data-bulk-select-row="" />
                                      </div>
                                    </td>
                                    <td className="fs--1 align-middle ps-0 py-3">
                                      <div className="form-check mb-0 fs-0">
                                        <input className="form-check-input" type="checkbox" checked={true} data-bulk-select-row="" />
                                      </div>
                                    </td>
                                    <td className="fs--1 align-middle ps-0 py-3">
                                      <div className="form-check mb-0 fs-0">
                                        <input className="form-check-input" type="checkbox" checked={true} data-bulk-select-row="" />
                                      </div>
                                    </td>
                                    <td className="fs--1 align-middle ps-0 py-3">
                                      <div className="form-check mb-0 fs-0">
                                        <input className="form-check-input" type="checkbox" checked={true} data-bulk-select-row="" />
                                      </div>
                                    </td>
                                    <td className="fs--1 align-middle ps-0 py-3">
                                      <div className="form-check mb-0 fs-0">
                                        <input className="form-check-input"  checked={true} type="checkbox" data-bulk-select-row="" />
                                      </div>
                                    </td>
                                  </tr>
                                ))) : (
                                    // Render a message when no transactions found
                                    <p>No transactions found.</p>
                                )
                                )
                            }






                        </tbody>
                    </table>
                </div>

                        </div>

                         {/* <DataTable
                                columns={dataTableTitle}
                                data={networkLists}
                                pagination
                                theme="solarized"
                                title={"Network List"}
                            /> */}


                        {/* <TransactionHistory transaction_type={ Array.from({ length: 84 - 63 + 1 }, (_, index) => 63 + index) } title="POS Terminal Transactions"/> */}


                    </div>






                </>
            )

            }
        </>
    );
}

export default Network;
