import React from 'react';
import { Card } from 'react-bootstrap';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Promotion = (props) => {
  // console.log(sliders.length);
  const desktopHeight = '230px';
  const mobileHeight = '150px';
  const mediaQuery = window.matchMedia('(max-width: 768px)').matches;

  const imageHeight = mediaQuery ? mobileHeight : desktopHeight;

  const sliders = props.user.sliders;
  if (sliders.length > 0) {
    return (
      <div className="card h-100 hover-actions-trigger">
        <div className="card border border-300 h-100 w-100 oveflow-hidden">
          <div className="bg-holder d-block bg-card"
          ></div>


          <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true}>
            {sliders.map((slider, index) => (

              // <div className="bg-holder bg-card"
              //     key={index}
              //     style={{
              //       backgroundImage: `url(${slider.image_url})`,
              //       backgroundSize: "cover",
              //       height: "230px",
              //     }}
              //   >
              //     <Card.Text className='badge bg-dark badge-lg' style={{ position: 'absolute', top: '10px', left: '10px' }}>
              //       Advert
              //     </Card.Text>
              //   </div>


            

              // {/* </div> */}

              <div key={index} style={{ position: 'relative' }} className="card-body p-0">
                <img
                  style={{ objectFit: 'cover', height: imageHeight }}
                  src={slider.image_url}
                  className="w-100 card-img"
                  alt={slider.company_id}
                />
                <Card.Text className='badge bg-dark badge-lg' style={{ position: 'absolute', top: '10px', left: '10px' }}>
                  Advert
                </Card.Text>
              </div>

            ))}
          </Carousel>
        </div>
      </div>


    );

  } else {
    return (

      <Card className="text-white">
        <img style={{ objectFit: 'cover', height: '230px' }} src={'https://wbalite.com/image/applyCard.jpg'} className="d-block w-100" alt={'defalut'} />
        <Card.Text className='badge bg-dark badge-lg' style={{ position: 'absolute', top: '10px', left: '10px' }}>Advert</Card.Text>


      </Card>
    );
  }
};

export default Promotion;
