import React ,{ useEffect }from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import _404 from './../../assets/img/error/404.png'
import dark_404 from './../../assets/img/error/dark_404.png'
import _ilustration_404 from './../../assets/img/error/404-illustration.png'
import dark_illustration_404 from './../../assets/img/error/dark_404-illustration.png'
function NoPage (){
    const navigate = useNavigate();
    const goBack = () => {
        // console.log('good morning');
        navigate(-1);
      };
    
    // render() {
        return (
            // <div>
            //     <p className="login-box-msg">Page Not Found</p>
            //     <h1 className="login-box-msg text-uppercase">

            //         <span className="error-message text-danger">404 Or Unauthorized to this Page</span>
            //         {/* <Link to={navigation(-1)}>Go Back</Link> */}

            //     </h1>

            //     <Button onClick={goBack}>
            //         Go Back</Button>


               


            // </div>


            <div className="px-3">
          <div className="row min-vh-100 flex-center p-5">
            <div className="col-12 col-xl-10 col-xxl-8">
              <div className="row justify-content-center align-items-center g-5">
                <div className="col-12 col-lg-6 text-center order-lg-1">
                    <img className="img-fluid w-lg-100 d-dark-none" src={_ilustration_404} alt="" width="400" />
                    <img className="img-fluid w-md-50 w-lg-100 d-light-none" src={dark_illustration_404} alt="" width="540" /></div>
                <div className="col-12 col-lg-6 text-center text-lg-start">
                    
                    <img className="img-fluid mb-6 w-50 w-lg-75 d-dark-none" src={_404} alt="" />
                
                <img className="img-fluid mb-6 w-50 w-lg-75 d-light-none" src={dark_404} alt="" />
                  <h2 className="text-800 fw-bolder mb-3">Page Missing!</h2>
                  <p className="text-900 mb-5">But no worries! Our ostrich is looking everywhere <br className="d-none d-sm-block" />while you wait safely. </p><span className="btn btn-lg btn-primary" onClick={goBack}>Go Back</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        )
    // }
}

export default NoPage;