import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import NumberFormater from '../extra/Formater';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import AuthenticateService from '../AuthenticateService';

const Wallet = (props) => {
    const { token, logout } = AuthenticateService();

    const cardStyle = {
        // backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://safe.wbalite.com/assets/images/wiredbanking_logo_outline.png')",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        // opacity: 0.2,
        height:'5.43em'
    };

    const user = props.user;
    const wallets = user.wallets;
    const chipStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        background: '#808080',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
    };
        const [showBalance, setShowBalance] = useState(false);

    const handleToggleBalance = () => {
        setShowBalance(!showBalance);
    };

    const handleCopyToClipboard = (wallet) => {
        const textarea = document.createElement('textarea');
        textarea.value = wallet;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        toast.info(wallet + 'Copied successfully', {
            position: toast.POSITION.TOP_RIGHT
        });
    };


    if (wallets.length > 0) {
        return (

            // <Card className='bg-danger p-2'>
            <div className="card h-100 hover-actions-trigger">
                           <div className="card border border-300 h-100 w-100 overflow-hidden">
                       <div className="bg-holder d-block bg-card" style={{ backgroundImage: "url(../assets/img/spot-illustrations/32.png)", backgroundPosition: "top right" }}></div>
                       <div className="d-dark-none">
                           <div className="bg-holder d-none d-sm-block d-xl-none d-xxl-block bg-card" style={{ backgroundImage: "url(../assets/img/spot-illustrations/21.png)", backgroundPosition: "bottom right", backgroundSize: "auto" }}></div>
                       </div>
                       <div className="d-light-none">
                           <div className="bg-holder d-none d-sm-block d-xl-none d-xxl-block bg-card" style={{ backgroundImage: "url(../assets/img/spot-illustrations/dark_21.png)", backgroundPosition: "bottom right", backgroundSize: "auto" }}></div>
                       </div>
                <Carousel  showThumbs={false} autoPlay={false} infiniteLoop={true} swipeable={true}>
                    {wallets.map((wallet, index) => (
                        // <div className="card-body position-relative" key={index}>
                        //     <div className="badge badge-phoenix fs--2 badge-phoenix-warning mb-3">
                        //         <span className="fw-bold">{wallet.product_name}</span>
                        //             <FontAwesomeIcon icon ={faAward}  className='ms-1'/>
                               
                        //     </div>
                        //     <h3 className="mb-2">{wallet.bank}</h3>
                        //     <p className="text-700 fw-semi-bold">Balance <sup>(NGN)</sup> :  <span className='ml-1 font-weight-bold'>
                        //         {showBalance ? (
                        //             <NumberFormater className="mr-3" num={wallet.wallet_balance} />
                        //         ) : (
                        //             <span style={{ fontSize: '1.2em' }}>*** **</span>
                        //         )}
                        //     </span> <FontAwesomeIcon onClick={handleToggleBalance} icon={showBalance ? faEye : faEyeSlash} className="ml-3" />
                           
                        //     </p>


                        //     <p className="text-700 fw-semi-bold">Account Number :                      <span>
                        //         {wallet.account_number} <i className='ml-3 fa fa-copy' onClick={() => handleCopyToClipboard(wallet.account_number)}>

                        //         </i>
                        //             <ToastContainer />
                        //     </span>
                        //     </p>
                        // </div>


                    
                        <div className="card-body"  key={index}>
                          <div className="d-flex align-items-center">
                            <h4 className="mb-md-2 line-clamp-1 lh-sm flex-1 me-5">{wallet.bank}</h4>
                            
                          </div><span className="badge badge-phoenix fs--2 mb-4 badge-phoenix-warning"><span className="badge-label">{wallet.product_name} <FontAwesomeIcon icon ={faAward}  className='ms-1'/></span></span>
                          <div className="d-flex align-items-center text-center mb-4">
                            <span className="fa-solid fa-credit-card me-2 text-700 fs--1 fw-extra-bold" />
                            <p className="fw-bold mb-0 lh-1">Balance <sup>(NGN)</sup> :  <span className='ms-1 text-1100'>
                                 {showBalance ? (
                                    <NumberFormater className="mr-3" num={wallet.wallet_balance} />
                                ) : (
                                    <span style={{ fontSize: '1.2em' }}>*** **</span>
                                )}
                            </span> <FontAwesomeIcon onClick={handleToggleBalance} icon={showBalance ? faEye : faEyeSlash} className="ml-3" />                           
                              </p>
                          </div>
                          <div className="d-flex align-items-center mb-1"><span className="fa-solid fa-user me-2 text-700 fs--1 fw-extra-bold" />
                            <p className="fw-bold mb-0 text-truncate lh-1">Act No: <span className="fw-semi-bold text-primary ms-1"> 
                                 {wallet.account_number} <i className='ml-3 fa fa-copy' onClick={() => handleCopyToClipboard(wallet.account_number)}>

                                 </i>
                                     <ToastContainer />
                            </span></p>
                          </div>
                          
                          
                        </div>
                    ))}
                </Carousel>
                </div>

                     </div>

// </div>
        );

    } else {
        return (

            <Card style={cardStyle}>
                <Carousel showThumbs={false} autoPlay={false} infiniteLoop={true} swipeable={true}>


                    <Card.Body>
                        <Card.Title className='badge bg-primary'>{'NILL'}</Card.Title>
                        <Card.Subtitle className="mb-1 text-muted">{'NIL'}</Card.Subtitle>
                        <Card.Text className='text-danger'>{'nill'}</Card.Text>

                        <div className="d-flex justify-content-between">
                            <Card.Text>Balance ({user.currency})</Card.Text>
                            <Card.Text> <span>

                                <NumberFormater className="mr-3" num={0} />
                            </span>  <i className='fa fa-eye ml-auto'></i></Card.Text>
                        </div>

                        <div className="d-flex justify-content-between">
                            <Card.Text>Account Name</Card.Text>
                            <Card.Text>{user.first_name} {user.last_name}</Card.Text>
                        </div>


                    </Card.Body>

                </Carousel>

            </Card>

        );
    }
};

export default Wallet;
