import React, { useState, useEffect, useMemo } from 'react';
import AuthenticateService from '../../AuthenticateService';
import { toast } from 'react-toastify';
// import DataTable from 'react-data-table-component';
// import { MaterialReactTable } from 'material-react-table';
import { Button, Card, FormGroup, Modal, Spinner } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';

// import { Box, Button } from '@mui/material';

// import FileDownloadIcon from '@mui/icons-material/FileDownload';
// import { CSVLink } from 'react-csv';
// import { PDFDownloadLink, Document, Page, Text } from '@react-pdf/renderer';
// import DataTable from 'datatables.net-dt';
// import 'datatables.net-bs4';
// import 'datatables.net-responsive';
// import 'datatables.net-buttons-bs4';
// import 'datatables.net-buttons/js/buttons.colVis';
// import 'datatables.net-buttons/js/buttons.html5';
// import 'datatables.net-buttons/js/buttons.flash';
// import 'datatables.net-buttons/js/buttons.print';
// import { DataTable } from 'datatables.net-dt';
// import './datatables.net-responsive-dt';
// import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here





const TransactionHistory = (props) => {


    const [transactions, setTransactions] = useState([]);
    const { http, user } = AuthenticateService();
    const { token, logout } = AuthenticateService();
    const [isFilter, setIsFilter] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [receipt, setReceipt] = useState([])

    const logoutUser = () => {
        if (token != undefined) {
            logout();
        }
    }

    const [isLoading, setIsLoading] = useState(true);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    // console.log(props);
    const params = {
        // page: 1
    };

    // params.from = '2023-06-1';
    // params.to = '2023-06-05';
    if (fromDate && toDate) {
        params.from = fromDate;
        params.to = toDate;
    }


    //   console.log(params);


    const handleCloseReceiptModal = () => {
        setShowModal(false);

    };

    const getTransactions = async () => {

        if (fromDate && toDate) {
            setIsFilter(true)
        }
        try {
            const response = await http.get('/transactions/history', {
                params
            });
            if (fromDate && toDate) {
                setIsFilter(true)
            }

            if (response.status === 200) {

                let data = response.data.data;
                if (data.current_page) {
                    data = data.data

                }
                if (Object.keys(props).length !== 0 && props.transaction_type) {
                    var filteredData = data.filter(item => {
                        if (Array.isArray(props.transaction_type)) {
                            return props.transaction_type.includes(item.transaction_type_id);
                        } else {
                            return item.transaction_type_id == props.transaction_type;
                        }

                    });

                    data = filteredData;
                }
                setIsLoading(false);
                setTransactions(data);
                setIsFilter(false)

            } else {
                // console.log('Error: Non-200 status code');
                setIsFilter(false)

            }
        } catch (error) {
            // logout();
            if (error.response) {
                if (error.response.status == 401) {
                    logout();
                }
            } else {
                toast.error('Something Went Wrong')
            }
            setIsFilter(false)

            // console.log(error.response.status);
        } finally {
            setIsLoading(false);
            setIsFilter(false)

        }
    };

    useEffect(() => {
        getTransactions();
    }, []);


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true,
        };
        return date.toLocaleDateString("en-US", options);
    };

    const truncateString = (str, maxLength) => {
        if (str.length <= maxLength) {
            return str;
        } else {
            return str.slice(0, maxLength) + '...';
        }
    }


    const handleViewClick = (transat) => {

        setReceipt(transat)
        console.log(receipt);
        setShowModal(true);
        // console.log(loan);
    };






    const statuses = ['Credit', 'Debit'];
    const statuColors = ['success', 'danger'];
    const remarkColor = ['warning', 'success', 'danger', 'secondary', 'light', 'primary'];
    const remarkStatus = ['Pending', 'Success', 'Failed', 'Awaiting', 'Reverse', 'Initiate'];



    return (
        <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white pt-7 border-y border-300">
            <div data-list='{"valueNames":["product","customer","rating","review","time"],"page":6}'>
                <div className="row align-items-end justify-content-between pb-5 g-3">
                    <div className="col-auto">
                        <p className="text-700 lh-sm mb-0">{props.title ? props.title : ""}</p>
                    </div>
                    <div className="col-12 col-md-auto">
                        <div className="row g-2 gy-3">
                            <div className="col-auto flex-1">
                                <div className="search-box">
                                    <div className="d-flex">
                                        <div className="d-flex flex-column me-2">
                                            <label htmlFor="fromDate">From</label>
                                            <input id="fromDate" className="form-control form-control-sm" onChange={(e) => {
                                                setFromDate(e.target.value);
                                            }} type="date"
                                                placeholder="From"
                                                aria-label="From"
                                                max={new Date().toISOString().split('T')[0]}
                                            />
                                        </div>
                                        <div className="d-flex flex-column">
                                            <label htmlFor="toDate">To</label>
                                            <input
                                                id="toDate"
                                                onChange={(e) => {
                                                    setToDate(e.target.value);
                                                }}

                                                className="form-control form-control-sm"
                                                type="date"
                                                placeholder="To"
                                                aria-label="To"
                                                max={new Date().toISOString().split('T')[0]}

                                            />
                                        </div>





                                    </div>



                                    <button className='my-2 btn btn-info btn-block' onClick={() => {
                                        getTransactions();
                                        // console.log(params);
                                    }}
                                        disabled={!(fromDate && toDate)}
                                    >
                                        {isFilter ? (
                                            <>
                                                <Spinner animation="border" size="sm" />Filtering...
                                            </>
                                        ) : (
                                            "Filter"
                                        )}

                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div >

                    <div className="table-responsive mx-n1 px-1 scrollbar">
                        <table className="table fs--1 mb-0 border-top border-200">
                            <thead>
                                {isLoading ? (
                                    <tr>
                                        <td>
                                            <Spinner animation="border" variant="primary" />

                                        </td>
                                    </tr>
                                ) : (
                                    <tr>
                                        <th className="sort align-middle pe-5" scope="col"  >REFERENCE</th>
                                        <th className="sort align-middle pe-5" scope="col"  >DESCRIPTION</th>
                                        <th className="sort align-middle text-end" scope="col"  >REMARKS</th>
                                        <th className="sort align-middle text-end" scope="col"  >TRANSACTION</th>
                                        <th className="sort align-middle text-end" scope="col" >CREDIT</th>
                                        <th className="sort align-middle text-end" scope="col" >DEBIT</th>
                                        <th className="sort align-middle text-end" scope="col" >BALANCE</th>
                                        <th className="sort align-middle text-end" scope="col" >DATE AND TIME</th>
                                        <th className="sort align-middle text-end" scope="col" ></th>

                                    </tr>
                                )}
                            </thead>
                            <tbody className="list">
                                {isLoading ? (
                                    <tr>
                                        <td>
                                            <Spinner animation="border" variant="primary" />

                                        </td>
                                    </tr>

                                )
                                    :
                                    (transactions.length > 0 ? (transactions.map((transaction, index) => (
                                        <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                            <td className="align-middle product white-space-nowrap">{transaction.transaction_reference}</td>
                                            <td className="align-middle customer white-space-nowrap" style={{ wordWrap: 'break-word' }}>
                                                {truncateString(transaction.description, 50)}
                                            </td>


                                            <td className="align-middle text-start ps-5 status">
                                                <span className={`badge badge-phoenix fs--2 badge-phoenix-${remarkColor[transaction.transaction_remarks_id - 1]}`}>
                                                    <span className="badge-label">{remarkStatus[transaction.transaction_remarks_id - 1]}</span>
                                                    <span className="ms-1" data-feather="check" style={{ height: '12.8px', width: '12.8px' }}></span>
                                                </span>
                                            </td>

                                            <td className="align-middle text-start ps-5 status">
                                                <span className={`badge badge-phoenix fs--2 badge-phoenix-${statuColors[transaction.credit_debit_type_id - 1]}`}>
                                                    <span className="badge-label">{statuses[transaction.credit_debit_type_id - 1]}</span>
                                                    <span className="ms-1" data-feather="check" style={{ height: '12.8px', width: '12.8px' }}></span>
                                                </span>
                                            </td>


                                            <td className="align-middle customer white-space-nowrap">{transaction.credit_debit_type_id == 1 ? transaction.amount : ''}</td>
                                            <td className="align-middle customer white-space-nowrap">{transaction.credit_debit_type_id == 2 ? transaction.amount : ''}</td>

                                            <td className="align-middle customer white-space-nowrap">{transaction.balance_after}</td>

                                            <td className="align-middle text-start time white-space-nowrap">
                                                <div className="">
                                                    <h6 className="text-1000 mb-0"> {formatDate(transaction.created_at)} </h6>
                                                </div>
                                            </td>

                                            <td className="align-middle white-space-nowrap text-end pe-0">
                                            <div className="font-sans-serif btn-reveal-trigger position-static">
                                                <button className="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal fs--2" type="button" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent">
                                                    <span className="fas fa-ellipsis-h fs--2" />
                                                    </button>
                                                        <div className="dropdown-menu dropdown-menu-end py-2">
                                                            <a className="dropdown-item" href="#" onClick={() => handleViewClick(transaction)} >Receipt</a>
                                                            {/* <a className="dropdown-item" href="#">Download</a> */}
                                                            
                                                        </div>
                                                        </div>

                                            </td>


                                        </tr>
                                    ))) : (
                                        // Render a message when no transactions found
                                        <tr>

                                            <td>No transactions found.</td>
                                        </tr>
                                    )
                                    )
                                }
                            </tbody>
                        </table>
                    </div>





                 



                </div>



                <Modal show={showModal} onHide={handleCloseReceiptModal} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div class="d-flex align-items-center justify-content-between">
                                    <h3 class="mb-0">
                                         {receipt.transaction_reference} Receipt
                                    </h3>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Card>
                                <Card.Body>

                                    <div className="text-center">
                                        <h2> ₦{receipt.amount}</h2>

                                        <span className={`badge badge-phoenix fs--2 badge-phoenix-${remarkColor[receipt.transaction_remarks_id - 1]}`}>
                                                    <span className="badge-label">{remarkStatus[receipt.transaction_remarks_id - 1]}</span>
                                                    <span className="ms-1" data-feather="check" style={{ height: '12.8px', width: '12.8px' }}></span>
                                        </span>

                                        <span className={`badge badge-phoenix fs--2 badge-phoenix-${statuColors[receipt.credit_debit_type_id - 1]}`}>
                                                    <span className="badge-label">{statuses[receipt.credit_debit_type_id - 1]}</span>
                                                    <span className="ms-1" data-feather="check" style={{ height: '12.8px', width: '12.8px' }}></span>
                                                </span>



                                    </div>
                                    <div class="d-flex justify-content-between mb-2">
                                        <h6 class="text-900 fw-semi-bold">Amount</h6>
                                        <h6 class="text-900 fw-semi-bold">{receipt.amount}</h6>
                                    </div>

                                    <div class="d-flex justify-content-between mb-2">
                                        <h6 class="text-900 fw-semi-bold">Reference</h6>
                                        <h6 class="text-900 fw-semi-bold">{receipt.transaction_reference}</h6>
                                    </div>


                                    <div class="d-flex justify-content-between mb-2">
                                        <h6 class="text-900 fw-semi-bold">Naration</h6>
                                        <h6 class="text-900 fw-semi-bold">{receipt.description}</h6>
                                    </div>

                                    <div class="d-flex justify-content-between mb-2">
                                        <h6 class="text-900 fw-semi-bold">Date</h6>
                                        <h6 class="text-900 fw-semi-bold">
                                            {formatDate(receipt.created_at)}


                                        </h6>
                                    </div>


                                   

                                   


                                    

                                        <button className="btn btn-primary w-100 mt-3" onClick={() =>''}>Print</button>
                                 

                                </Card.Body>
                            </Card>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseReceiptModal}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>





            </div>
        </div>
    );
}

export default TransactionHistory;