import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button, Spinner } from 'react-bootstrap';
import AuthenticateService from './AuthenticateService';
import axios from 'axios';
import Sidebar from './Sidebar';
import Header from './Header';
import TransactionHistory from './extra/dashboard/TransactionHistory';
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import PreLoader from './extra/PreLoader';

const UssdPOS = () => {

  const { http, user } = AuthenticateService();
  const [networkProvider, setNetworkProvider] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [amount, setAmount] = useState('');
  const [accountNumber, setAccountNumber] = useState(user.wallets[0].account_number);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBuying, setIsBuying] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const defaultAmount = [1000, 2000, 5000, 10000, 20000, 50000];
  const [isVerifying, setIsVerifying] = useState(false);
  const { token, logout } = AuthenticateService();
  const [countdown, setCountdown] = useState(30);


  useEffect(() => {
    document.title = user.first_name + " " + user.last_name + "- Airtime"; // Set the page title here
  }, []);


 

   

  const requestMoney = async (e) => {
    
    e.preventDefault();
    setCountdown(120)
    setIsVerifying(true);
    try {

      setIsBuying(true)
      const response = await axios.post('https://safe.wbalite.com/vas/api/payment/request', {
        amount: amount,
        phone: phoneNumber,
        account_number: accountNumber,
      }).then((res) => {

        if (res.data.status == true) {
          setIsBuying(false)

          const reference = res.data.data.reference; 
          console.log(reference);
          setIsVerifying(true);
          // setCountdown(60);
          // if (countdown < 30) {
            // console.log(countdown);

              try {
                const verify =  axios.get('https://safe.wbalite.com/vas/api/payment/request/'+reference)
                .then((res)=>{

                  
                if (res.status == 200) {
                   if (res.data.data.apiResponse.success == true) {
                      Swal.fire({
                        title: res.data.data.statusDescription,
                        text: 'Your request of NGN' + amount + ' from ' + phoneNumber + ' to ' + accountNumber + ' ' +res.data.data.statusDescription,
                        icon: res.data.data.statusDescription=='Approved' ? 'success':'warning',
                        confirmButtonText: 'OK',
                      })
                      setCountdown(0);
                      setIsBuying(false);
                      setIsVerifying(false);
                   
                   }else{
                        Swal.fire({
                        title: 'Failed',
                        text: 'Your request of NGN' + amount + ' from ' + phoneNumber + ' to ' + accountNumber + ' was ' +res.data.data.apiResponse.message,
                        icon: 'error',
                        confirmButtonText: 'OK',
                      })
                      setCountdown(0);
                      setIsBuying(false);
                      setIsVerifying(false);
                   }
                    // console.log(res);
    
                } else {
                    // console.log('Error: Non-200 status code');
                    setCountdown(0);
                    setIsBuying(false);
                    setIsVerifying(false);
    
                }

                }).catch((error)=>{
                    //   if (error.response) {
                    //     if (error.response.status == 401) {
                    //         logout();
                    //     }
                    // }
                    console.log(error);
                  setCountdown(0);
                  setIsBuying(false);
                  setIsVerifying(false);

                });
    
                
            } catch (error) {
              console.log(error);
                // if (error.response) {
                //     if (error.response.status == 401) {
                //         logout();
                //     }
                // } else {
                //     toast.error('Something Went Wrong')
                // }
                
                } 
              // }
               
          

        } else {
        }
      })
        .catch((error) => {
          setIsBuying(false)
          console.log(error);
          // Swal.fire({
          //   title: 'Failed',
          //   text: error.response.data.message,
          //   icon: 'error',
          //   confirmButtonText: 'OK',
          // })

          // toast.error('Airtime request failed, ' + error.response.data.message);

        });
    } catch (error) {
      console.log(error);
      setIsBuying(false)
      // if (error.response.status == 401) {
      //   logout();
      // }
      // Swal.fire({
      //   title: 'Failed',
      //   text: error.response.data.message,
      //   icon: 'error',
      //   confirmButtonText: 'OK',
      // })

      // toast.error('Airtime request failed, ' + error.response.data.message);

    }
  };




  useEffect(() => {
    let timerId;
  
    if (isVerifying && countdown > 0) {
      timerId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
  
    if (countdown < 1) {
      setIsVerifying(false);
      setIsBuying(false)
      clearInterval(timerId);
    }
  
    return () => {
      clearInterval(timerId);
    };
  }, [isVerifying, countdown]);
  

  const formattedCountdown = `${Math.floor(countdown / 60)
    .toString()
    .padStart(2, '0')}:${(countdown % 60).toString().padStart(2, '0')}`;



  return (
    <>
      {isLoading ? (
        <PreLoader />

      ) : (
        <>
          <Sidebar />
          <Header user={user} />

          <div className="content">
            <form className="mb-9" onSubmit={requestMoney}>
              <div className="row g-5">
                <div className="col-xl-8">
                  <h4 className="fs-1 mb-4">Request Payment Via Soft USSD</h4>


                  <div class="alert alert-outline-warning d-flex align-items-center" role="alert">
                    <span class="fas fa-info-circle text-warning fs-3"></span>
                    Please note:  Customer must have been subscribe to his bank USSD channel for transaction to be successful.  We require the amount you want the customer to pay and phone number of the customer, while you wait for the customer to complete transaction from his phone using his bank pin ( NGN 30)  apply                  </div>

                  <div className="row gx-3 gy-4">
                    <div className="col-sm-6 col-md-6">
                      <div className="form-floating">
                        <select
                          className="form-select"
                          id="floatingSelectTask"
                          value={accountNumber}
                          onChange={(e) => setAccountNumber(e.target.value)}
                          required
                        >

                          {user.wallets.map((wallet, index) => (
                            <option key={index} value={wallet.account_number}>{wallet.bank} ({wallet.account_number} )</option>
                          ))}

                        </select>
                        <label htmlFor="floatingSelectTask">Account Number</label>
                      </div>
                    </div>

                    <div className="col-sm-6 col-md-6">
                      <div className="form-floating">
                        <input
                          className="form-control"
                          id="floatingEventInput"
                          type="text"
                          placeholder="Phone Number"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          required
                        />
                        <label htmlFor="floatingEventInput">Phone Number</label>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6">
                      <div className="form-floating">
                        <input
                          className="form-control"
                          id="floatingEventInput"
                          type="number"
                          step={2}
                          placeholder="Amount"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          required
                        />
                        <label htmlFor="floatingEventInput">Amount</label>
                      </div>
                    </div>

                    <div className="col-sm-6 col-md-6">
                      <div className="row">
                        {defaultAmount.map((amt, index) => (
                          <div key={index} className="col-4">
                            <span
                              onClick={() => setAmount(amt)}
                              className={`btn btn-sm  rounded-pill w-100 me-3 mb-2  fs--1 fs-sm-0 ${amount === amt ? 'btn-warning' : 'btn-outline-warning'}`}
                            >
                              {amt}

                            </span>
                          </div>
                        ))}
                      </div>
                    </div>








                    <div className="col-12 col-sm-auto col-xl-4">
                      <div className="d-flex">
                        <Button type="submit" variant="primary"


                          disabled={isBuying}>
                          {isBuying ? (
                            <>
                              <Spinner animation="border" size="sm" /> Requesting...
                            </>
                          ) : (
                            "Request Now"
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <TransactionHistory transaction_type={101} title="Airtime Transactions"/>



            <Footer />
          </div>


          <Modal show={isVerifying} onHide={() => setIsVerifying(false)} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>Awaiting  confirmation of payment, please holdon</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h6>
                This can take about <span className='font-weight-bold display-3'>{formattedCountdown}</span> <Spinner animation="border" size="sm" /> Confirming...
              </h6>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>

          <ToastContainer />
        </>
      )

      }
    </>
  );
}

export default UssdPOS;
