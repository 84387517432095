import React, { useState, useEffect } from 'react'
import AuthenticateService from './AuthenticateService';
import Promotion from './carousel/Promotion';
import Wallet from './carousel/Wallet';
import NumberFormater from './extra/Formater';
import { useNavigate, Link } from 'react-router-dom';
import QuickService from './extra/dashboard/QuickService';
import Sidebar from './Sidebar';
import Header from './Header';
import WeeklyTransactionPerformance from './extra/dashboard/WeeklyTransactionPerformance';
import MonthlyTransaction from './extra/dashboard/MonthlyTransactionChat';
import TransactionHistory from './extra/dashboard/TransactionHistory';
import Footer from './Footer';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faBullhorn, faLightbulb, faMoneyBill, faMoneyBill1, faPhoneFlip, faTabletAndroid, faUser } from '@fortawesome/free-solid-svg-icons';
import PreLoader from './extra/PreLoader';
import ElectricityBill from './Electricity';

const Dashboard = (props) => {
  const navigate = useNavigate();
  const { http, user } = AuthenticateService();
  const [userDetails, setUserDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { token, logout } = AuthenticateService();


  const getUserDetails = async () => {
    try {
      const response = await http.get('/users/get-details', {
        // params: { page: 1 },
      });
      if (response.status === 200) {
        const data = response.data.data;
        setUserDetails(data);
      } else {
        console.log('Error: Non-200 status code');
      }
    } catch (error) {
      // logout();
      if (error.response.status==401){
        logout();
    }
    } finally {
      setIsLoading(false);
    }
  };

  const logoutUser = () => {
    if (token != undefined) {
      logout();
    }
  }
  useEffect(() => {
    document.title = user.first_name + " " + user.last_name + "- Dashboard"; // Set the page title here
    getUserDetails();
  }, []);

  // console.log(user);





  const { promotion, setPromotion } = useState('https://wbalite.com/image/applyCard.jpg')
  return (
    <>

      {isLoading ? (
        <PreLoader />

      ) : (
        <>
          <Sidebar user={userDetails} />
          <Header user={userDetails} />


          <div className="content">

            <div className="pb-5">
              <div className="scrollbar">
                <div className="d-flex justify-content-between">
                  <Link className="icon-nav-item" to="/airtime">
                    <div className="icon-container mb-2"><span className="fs-4 uil uil-mobile-android" /></div>
                    <p className="nav-label">Airtime</p>
                  </Link>
                  <Link className="icon-nav-item" to="/">
                    <div className="icon-container mb-2"><span className="fs-4 uil fa fa-wifi" /></div>
                    <p className="nav-label">Data</p>
                  </Link>
                  <Link className="icon-nav-item" to="/cable-tv-subscription">
                    <div className="icon-container mb-2"><span className="fs-4 uil uil-monitor" /></div>
                    <p className="nav-label">TV</p>
                  </Link>

                  <Link className="icon-nav-item" to="/electricity-subscription">
                  <div className="icon-container mb-2"><FontAwesomeIcon className="fs-4 uil" icon={faLightbulb} /> </div>
                    <p className="nav-label">Electricity</p>
                  </Link>

                  <Link className="icon-nav-item" to="/transfer">
                    <div className="icon-container mb-2"><span className="fs-4 uil fa fa-arrow-alt-circle-up" />  </div>
                    <p className="nav-label">Transfer</p>
                  </Link>
                  <Link className="icon-nav-item" to="/atm-card">
                    <div className="icon-container mb-2"><span className="fs-4 uil fa fa-credit-card" /></div>
                    <p className="nav-label">Card</p>
                  </Link>
                  <Link className="icon-nav-item" to="/soft-ussd-pos">
                    <div className="icon-container mb-2"><span className="fs-4 uil fa fa-football-ball" /></div>
                    <p className="nav-label">USSD POS</p>
                  </Link>
                  <Link className="icon-nav-item" to="/loan">
                    <div className="icon-container mb-2"><FontAwesomeIcon className="fs-4 uil" icon={faMoneyBill1} /> </div>
                    <p className="nav-label">Loan</p>
                  </Link>

                  <Link className="icon-nav-item" to="/pos-terminal">
                    <div className="icon-container mb-2"><FontAwesomeIcon className="fs-4 uil" icon={faTabletAndroid} /> </div>
                    <p className="nav-label">POS</p>
                  </Link>

                </div>
              </div>
              {/* <h3 className='text-cente'> <b>You are welcome, {user.first_name} {user.last_name}</b> </h3> */}
              <div className="row g-4" style={{ margin: '0px' }}>
                <div className="col-12 col-xxl-6">

                  <div className="row g-3">
                    <div className="col-12 col-md-6">
                      {userDetails && userDetails.wallets && <Wallet user={userDetails} />}

                    </div>
                    <div className="col-12 col-md-6">
                      {userDetails && userDetails.wallets && <Promotion user={userDetails} />}

                    </div>
                    <div className="col-12 col-md-6">
                      <WeeklyTransactionPerformance />
                    </div>

                    <div className="col-12 col-md-6">
                      <div className="card h-100">
                        <div className="card-body d-flex flex-column">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h5 className="mb-2">Paying vs non paying</h5>
                              <h6 className="text-700">Last 7 days</h6>
                            </div>
                          </div>
                          <div className="d-flex justify-content-center pt-3 flex-1">
                            <div className="echarts-paying-customer-chart" style={{ height: '100%', width: '100%' }}></div>
                          </div>
                          <div className="mt-3">
                            <div className="d-flex align-items-center mb-2">
                              <div className="bullet-item bg-primary me-2"></div>
                              <h6 className="text-900 fw-semi-bold flex-1 mb-0">Paying customer</h6>
                              <h6 className="text-900 fw-semi-bold mb-0">30%</h6>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="bullet-item bg-primary-100 me-2"></div>
                              <h6 className="text-900 fw-semi-bold flex-1 mb-0">Non-paying customer</h6>
                              <h6 className="text-900 fw-semi-bold mb-0">70%</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    {/* <MonthlyTransaction/> */}

                  </div>
                </div>
              </div>
            </div>

            <TransactionHistory title="All Transactions"/>



            <Footer />
          </div>
        </>
      )
      }
    </>
  )
}

export default Dashboard;