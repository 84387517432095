import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button, Spinner } from 'react-bootstrap';
import AuthenticateService from './AuthenticateService';
import Sidebar from './Sidebar';
import Header from './Header';
import TransactionHistory from './extra/dashboard/TransactionHistory';
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select'
import Swal from 'sweetalert2';
import PreLoader from './extra/PreLoader';

const ElectricitySubscription = () => {

  const { http, user } = AuthenticateService();
  const [networkProvider, setNetworkProvider] = useState('');
  const [planType, setPlanType] = useState('');
  const [electricityDistribution, setElectricityDistribution] = useState('');
  const [amount, setAmount] = useState('');
  const [plan, setPlan] = useState('');
  const [transactionPin, setTransactionPin] = useState('');
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBuying, setIsBuying] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [dataDetails, setDataDetails] = useState([]);
  const defaultAmount = [500, 1000, 2000, 5000, 10000, 20000];
  const [isVerifying, setIsVerifying] = useState(false);
  const [accountName, setAccountName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [accountNameVerified, setAccountNameVerified] = useState(false);

  const { token, logout } = AuthenticateService();



  // console.log(user);

  const getElectricity = async () => {
    // console.log('hellow orld');
//       const response = await http.get('/transactions/errandpay/get-pos-terminals', {

    setIsLoading(true);
    try {
      setIsLoading(true);
      const response = await http.get('/get-meter-service', {
        params: {
          page: 1,
        },
      });
      if (response.status === 200) {
        let data = response.data;
        setElectricityDistribution(data);
        setIsLoading(false);

      } else {
        console.log('Error: Non-200 status code');
      }
    } catch (error) {
      // logout();
      if (error.response.status==401){
        logout();
      }
    }
  };
  useEffect(() => {
    document.title = user.first_name + " " + user.last_name + "- Electricity Bill"; // Set the page title here
  }, []);

  const lightType = [
    { type: 'prepaid', name: 'Prepaid'},
    { type: 'postpaid', name: 'Post Paid'},
  ];















  const validateForm = () => {
    if (!networkProvider || !cardNumber || !planType) {
      toast.error('Please fill in all fields');
      return false;
    }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      setShowPasswordModal(true);
    }
  };

  const handleContinue = async () => {
    if (validateForm()) {

      try {
        setIsBuying(true)
        const response = await http.post('/transactions/buy-electricity', {
          final_amount: amount,
          meter_number: cardNumber,
          electricity_disco: networkProvider.value,
          meter_type:planType.value,
          transaction_pin:transactionPin,
          phone_number:user.phone,
        });
        if (response.data.status === true) {
 
          setNetworkProvider('');
          setPlanType('');
          setAmount('');
          setTransactionPin('');
          setPlan('');
          setPlanType('');
          setAccountName('');
          setShowPasswordModal(false);
          setIsBuying(false);
          setTransactionPin('')
          Swal.fire({
            title: 'Successful',
            html: response.data.message +" <br/>  token : <b>"+ response.data.data.token +"</b>", // <br/> Unit : "+  response.data.data.unit ,
            icon: 'success',
            confirmButtonText: 'OK',
          })



        } else {

          Swal.fire({
            title: 'Failed',
            text: response.data.message,
            icon: 'error',
            confirmButtonText: 'OK',
          })
        }
      } catch (error) {
        setIsBuying(false)
        if (error.response.status==401){
          logout();
      }
        Swal.fire({
          title: 'Failed',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'OK',
        })
        // toast.error('Data request failed, ' + error.response.data.message);
      }
    }
  };


  const verifyAccountNumber = async (number) => {
    setCardNumber(number);
    try {
        setIsVerifying(true);
        const response = await http.post('/transactions/verify-meter-number', {
          meter_number: number,
          electricity_disco: networkProvider.value,
          type:planType.value,
        });


        // console.log(response);

        if (response.data.status === true) {
            toast.success(response.data.message);
            // console.log(response.data);
            setAccountNameVerified(true)
            setAccountName(response.data.data.meter_name)
        } else {
            toast.warning(response.data.message);
            setAccountNameVerified(false)
            setAccountName("");
        }

        setIsVerifying(false);
    } catch (error) {
      if (error.response.status==401){
        logout();
    }
        setIsVerifying(false);
        console.log(error);
        setAccountName("");
        Swal.fire({
          title: "Failed",
          text: 'Verification Failed: '+ error.response.data.message,
          icon: 'error',
          confirmButtonText: 'OK',
      })
        // toast.error('Data request failed, ' + error.response.data.message);

        // toast.error('Account verification failed: ');
    }
    // }
};



  useEffect(() => {
    getElectricity();
  }, []);

  // console.log(electricityDistribution);

  return (
    <>

      {isLoading ? (
        <PreLoader />

      ) : (


        <>
          <Sidebar />
          <Header user={user} />

          <div className="content">
            <form className="mb-9" onSubmit={handleSubmit}>
              <div className="row g-5">
                <div className="col-xl-12">
                  <h4 className="fs-1 mb-4">Distribution Company</h4>
                  <div className="row gx-3 gy-4">

                  <div className="col-sm-6 col-md-6">
                      <div className="form-floating">
                        <div className="form-floating">

                          <Select
                            id="floatingSelectTask"
                            value={planType}
                            onChange={setPlanType}
                            options={lightType.length > 0 && lightType.map((data) => ({
                              value: data.type,
                              label: data.name,
                            }))}
                            required

                            placeholder="Plan tType" 
                          />
                        </div>

                      </div>
                    </div>

                    <div className="col-sm-6 col-md-6">
                      <div className="form-floating">
                        <div className="form-floating">

                          <Select
                            id="floatingSelectTask"
                            value={networkProvider}
                            onChange={setNetworkProvider}

                            options={electricityDistribution.length > 0 && electricityDistribution.map((data) => ({
                              value: data.id,
                              label: data.name,
                            }))}
                            required
                            placeholder="DISCO" 
                          />
                        </div>

                      </div>
                    </div>

                    <div className="col-sm-6 col-md-6">
                      <div className="form-floating">
                        <input
                          className="form-control"
                          id="floatingEventInput"
                          type="text"
                          placeholder="Meter Number"
                          value={cardNumber}
                          onChange={(e) => {
                            setCardNumber(e.target.value);
                            console.log(e.target.value.length);
                              if (e.target.value.length > 10) {

                                verifyAccountNumber(e.target.value);
                              }
                          }}
                          disabled={!networkProvider}
                          required
                        />
                        <label htmlFor="floatingEventInput">Meter Number</label>
                      </div>
                    </div>


                    <div className="col-sm-6 col-md-6">
                    <div className="form-floating">
                      <input
                        className="form-control"
                        id="floatingEventInput"
                        type="text"
                        placeholder="Account Name"
                        value={accountName}
                        onChange={(e) => {
                          setAccountName(e.target.value);
                          
                         
                        }}
                        disabled={!accountNameVerified}

                        />
                        { isVerifying ? (
                        <button
                            type="button"
                            style={{
                                position: 'absolute',
                                top: '50%',
                                right: '10px',
                                transform: 'translateY(-50%)',
                                background: 'transparent',
                                border: 'none',
                                cursor: 'pointer',
                            }}
                            className="btn btn-link password-toggle"
                            
                        >
                            <Spinner animation="border" size="sm" />
                        </button>

                            ): (
                                ""
                            )}
                      <label htmlFor="floatingEventInput">Card Name</label>
                    </div>
                  </div>


                  <div className="col-sm-6 col-md-6">
                      <div className="row">
                        {defaultAmount.map((amt, index) => (
                          <div key={index} className="col-4">
                            <span
                              onClick={() => setAmount(amt)}
                              className={`btn btn-sm  rounded-pill w-100 me-3 mb-2  fs--1 fs-sm-0 ${amount === amt ? 'btn-warning' : 'btn-outline-warning'}`}
                            >
                              {amt}

                            </span>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="col-sm-6 col-md-6">
                      <div className="form-floating">
                        <input
                          className="form-control"
                          id="floatingEventInput"
                          type="number"
                          step={2}
                          placeholder="Amount"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          required
                        />
                        <label htmlFor="floatingEventInput">Amount</label>
                      </div>
                    </div>


                   




                    <div className="col-sm-6 col-md-6">
                      <div className="d-flex">
                        <button className="btn btn-info px-5 w-100 text-nowrap" type="submit">
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <TransactionHistory transaction_type={14} title="Electricity Bill Transactions"/>

            <Footer />
          </div>

          <Modal show={showPasswordModal} onHide={() => setShowPasswordModal(false)} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>Enter Transaction PIN</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-floating">
                <input
                  className="form-control"
                  type={showPassword ? 'number' : 'password'}
                  maxlength={4}

                  placeholder="Transaction Pin"
                  value={transactionPin}
                  onChange={(e) => setTransactionPin(e.target.value)}
                  required
                />
                <button
                  type="button"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    right: '10px',
                    transform: 'translateY(-50%)',
                    background: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                  className="btn btn-link password-toggle"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </button>
                <label htmlFor="floatingEventInput">Transaction PIN</label>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary"

                onClick={() => setShowPasswordModal(false)}>
                Cancel
              </Button>
              {/*<Button variant="primary" onClick={handleContinue}>
              Buy Airtime
            </Button> */}
              <Button variant="primary"


                onClick={handleContinue} disabled={isBuying}>
                {isBuying ? (
                  <>
                    <Spinner animation="border" size="sm" /> Loading...
                  </>
                ) : (
                  "Buy Now"
                )}
              </Button>
            </Modal.Footer>
          </Modal>

          <ToastContainer />
        </>
      )}
    </>
  );
};

export default ElectricitySubscription;
