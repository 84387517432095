import { React } from 'react';
import { Spinner } from 'react-bootstrap';

const  PreLoader =()=>{
    return (
<div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <Spinner animation="grow" className="text-center spinner-lg mx-auto d-block" variant="info" />
  </div>
    );
}


export default PreLoader;