import React, { useState, useEffect } from "react";
// import { useSignIn } from 'react-auth-kit'
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import AuthenticateService from "../auth/AuthenticateService";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

// import './Guest.css'
const Login = (props) => {
  const { http, setToken } = AuthenticateService();
  const [isLoging, setIsLoging] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const company = props.company;
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Welcome - Login";
  }, []);

  // console.log(company);

  const [formData, setFormData] = useState({ username: "", password: "" });
  const [error, setError] = useState("");
  const [logined, setLogined] = useState("");

  const onSubmit = (e) => {
    setError("");
    e.preventDefault();
    setIsLoging(true);

    if (formData.username === "" || formData.password === "") {
      setError("Username and password are required");
      return; // Return early if validation fails
    }
    setError("");
    // toast.info('Please Wait, Login in progress', {
    //     // autoClose: false, // Set autoClose to false to keep the toast visible until manually closed
    //   });

    http
      .post("/users/login", formData)

      .then((res) => {
        // console.log(res.status);

        if (res.status === 200) {
          setError("");
          setLogined(res.data.message);
          setToken(res.data.data.user, res.data.data.access_token);
        } else if (res.status === 203) {
          // console.log(res);
          sessionStorage.setItem("verify", JSON.stringify(res.data));
          navigate("otp");
        } else {
          // console.log(res.status);
          setError(res.message);
          setIsLoging(false);
        }
      })
      .catch((error) => {
        setError("Failed to Sign In : " + error?.response?.data?.message);
        setIsLoging(false);
      });
  };

  return (
    <div
      className="container"
      style={{
        backgroundImage: `url("https://safe.wbalite.com/${company?.logo?.replace(
          "public",
          "storage"
        )}")`,
        backgroundRepeat: "repeat",
      }}
    >
      <div className="row flex-center min-vh-100 py-5">
        <div className="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-3 bg-white">
          <a className="d-flex flex-center text-decoration-none" href="/">
            <div className="d-flex align-items-center fw-bolder fs-5 d-inline-block">
              <img
                src={
                  "https://safe.wbalite.com/" +
                  company?.logo?.replace("public", "storage")
                }
                alt=""
                width="100"
              />
            </div>
          </a>
          <div className="text-center mb-3">
            <h3 className="text-1000">{company?.company_name}</h3>
            {/* <h4 className="text-1000">Sign In</h4> */}
            <p className="text-700">{company?.address}</p>
            <ToastContainer />
            <h6 className="login-box-msg text-uppercase">
              <span className="error-message text-danger">{error}</span>
              <span className="error-message text-success">{logined}</span>
            </h6>
          </div>
          <form onSubmit={onSubmit}>
            <div className="mb-3 text-start">
              <label className="form-label" htmlFor="email">
                Username
              </label>
              <div className="form-icon-container">
                <input
                  className="form-control form-icon-input"
                  onChange={(e) =>
                    setFormData({ ...formData, username: e.target.value })
                  }
                  type="text"
                  placeholder="username"
                  required
                />
                <span className="fas fa-user text-900 fs--1 form-icon"></span>
              </div>
            </div>
            <div className="mb-3 text-start">
              <label className="form-label" htmlFor="password">
                Password
              </label>
              <div className="form-icon-container">
                <input
                  className="form-control form-icon-input"
                  id="password"
                  type={showPassword ? "text" : "password"}
                  onChange={(e) =>
                    setFormData({ ...formData, password: e.target.value })
                  }
                  placeholder="Password"
                  required
                />
                <span className="fas fa-key text-900 fs--1 form-icon"></span>

                <button
                  type="button"
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                  }}
                  className="btn btn-link password-toggle"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </button>
              </div>
            </div>
            <div className="row flex-between-center mb-7">
              <div className="col-auto">
                <div className="form-check mb-0">
                  <input
                    className="form-check-input"
                    id="basic-checkbox"
                    type="checkbox"
                    checked="checked"
                  />
                  <label
                    className="form-check-label mb-0"
                    htmlFor="basic-checkbox"
                  >
                    Remember me
                  </label>
                </div>
              </div>
              <div className="col-auto">
                <Link className="fs--1 fw-semi-bold" to="/forget-password">
                  Forgot Password?
                </Link>
              </div>
            </div>

            {isLoging ? (
              <>
                <Spinner animation="border" size="sm" /> Please wait...
              </>
            ) : (
              <button className="btn btn-primary w-100 mb-3">Sign In</button>
            )}
          </form>

          <div className="text-center">

            { company?.reg_status==0 ? (
                  <Link to="/register" className="fs--1 fw-bold">
                  Create an account
                </Link>
            ):(
              <a href={company?.reg_url} target="_blank" rel="noopener noreferrer" className="fs--1 fw-bold">
                  Create an account
              </a>
              
            ) }

          


          </div>
        </div>
      </div>
    </div>
  );
  // }
};

export default Login;
