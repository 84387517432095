import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TransactionHistory from './extra/dashboard/TransactionHistory';
export default function AuthenticateService(){
  const navigate = useNavigate()
  const API_URL = "https://safe.wbalite.com/api/v1/";

  const getToken = () =>{
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken;
}

const getUser = () =>{
    const userString = sessionStorage.getItem('user');
    const user_detail = JSON.parse(userString);
    return user_detail;
}

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const getPublicContent = () => {
    return axios.get(API_URL + "all");
  };
  
  const getUserBoard = () => {
    return axios.get(API_URL + "user");
  };
  
  const getModeratorBoard = () => {
    return axios.get(API_URL + "mod");
  };
  
  const getAdminBoard = () => {
    return axios.get(API_URL + "admin");
  };

  const getCompany =({company})=>{
    return axios.get('https://safe.wbalite.com/vas/api/'+company);
  }
  const getShortTransactionHistory=()=>{
    return axios.get(API_URL+"transactions/history?page=1");
  }

  const getAllTransactionHistory=()=>{
    return axios.get(API_URL+"api/v1/transactions/history");
  }

// const [transactionHistory]
const [token,setToken] = useState(getToken());
const [user,setUser] = useState(getUser());

const saveToken = (user,token) =>{
    sessionStorage.setItem('token',JSON.stringify(token));
    sessionStorage.setItem('user',JSON.stringify(user));
    setToken(token);
    setUser(user);
    navigate('/dashboard');
}

const logout = () => {
    sessionStorage.clear();
    navigate('/');
}

const http = axios.create({
    baseURL:API_URL,
    headers:{
        "Content-type" : "application/json",
        "Authorization" : `Bearer ${token}`
    }
});

return {
    setToken:saveToken,
    token,
    user,
    getToken,
    http,
    logout,
    // register,
    // login,
    getCompany,
    getCurrentUser,
    getShortTransactionHistory,
    getAllTransactionHistory,

}
}
