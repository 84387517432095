import React ,{ useEffect, useState }from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import AuthenticateService from '../auth/AuthenticateService';
import { Spinner } from 'react-bootstrap';
function OtpVerification (){
    // render() {

    const [formData, setFormData] = useState({ username: '', password: '' })
    const [error, setError] = useState('');
    const [isResending, setIsResending] = useState(false);

    const verifyToken = JSON.parse(sessionStorage.getItem('verify'));
    const navigate = useNavigate();
    // console.log(verifyToken.data.user_reference);
    const { http, setToken } = AuthenticateService();
    const [isLoging, setIsLoging]= useState(false)


    // console.log(verifyToken);
    if(verifyToken==null){
        // navigate('/');
        window.location.assign('/')
        sessionStorage.clear();

    }


    const resendOTP=()=>{
        setIsResending(true)

        http.post('/users/send-otp',{
            user_reference:verifyToken.data.user_reference,
            phone_number:verifyToken.data.phone_number
        })

            .then((res) => {
                // console.log(res);

                if (res.status === 200) {
                    setError('');
                    toast.success(res.data.message);
                    // sessionStorage.clear();
                    setIsResending(false)


                } else {
                    setError(res.message);
                    setIsResending(false)

                }
            })
            .catch((error) => {

                // setError('Failed to resend ')users/resend-otp
                // console.log(error.response.data.message);
                toast.error('Resend Failed : '+ error.response.data.message);
                setIsResending(false)


            });
    }

        const onSubmit = (e) => {
        
            setError('')
            setIsLoging(true)
            e.preventDefault()
    
            if (formData.otp === '' ) {
                setError('OTP is required');
                return; // Return early if validation fails
            }
            setError('');
            toast.info('Please Wait, Verification in progress', {
                // autoClose: false, // Set autoClose to false to keep the toast visible until manually closed
              });
    
            http.post('/users/verify-otp',{
                user_reference:verifyToken.data.user_reference,
                otp_code:formData.otp
            })
    
                .then((res) => {
                    // console.log(res.status);
    
                    if (res.status === 200 && res.data.status!==false) {
                        setError('');
                        navigate('/');
                        sessionStorage.clear();
                        setIsLoging(false)
    
                    }else {
                        setError(res.data.message);
                        setIsLoging(false)
                    }
                })
                .catch((error) => {
    
                    // setError('Failed to Sign In, Kindly check your username and password')
                    // console.log(error);
                    toast.error('Verification Failed : '+ error.response.data.message);
                    setIsLoging(false)
                });
    
        }
        return (
            <div className="container">

            <div className="row flex-center min-vh-100 py-5">
                <div className="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-3 bg-white">
                    <a className="d-flex flex-center text-decoration-none mb-4" href="/">
                        <div className="d-flex align-items-center fw-bolder fs-5 d-inline-block p-4">
                            {/* <img src="https://safe.wbalite.com/assets/images/wiredbanking_logo_outline.png" alt="wba" width="100" /> */}
                        </div>
                    </a>
                    <div className="text-center mb-7">
                        <h3 className="text-1000">Account Verification</h3>
                        {/* <p className="text-700">Get access to your account</p> */}
                        <ToastContainer />
                        <h6 className="login-box-msg text-uppercase">
                        <span className="error-message text-danger">{error}</span>
                        </h6>
                    </div>
                    <div className='text-center'>
                        {/* <Link to="#" 0pdat3xf1nd37 onClick={resendOTP} className="resendOTP"> */}
                        <div class="alert alert-outline-warning d-flex align-items-center" role="alert">
                            <span class="fas fa-info-circle text-warning fs-3"></span>
                            <p class="mb-0 flex-1">You haven't got OTP, please { isResending ? (

                                    <>
                                    <Spinner animation="grow" size="sm" /> Sending...
                                    </>
                                    ):(
                                    <Link to='#' onClick={resendOTP}>resend</Link>
                                    )}
                            
                            </p>
                            <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                                     
                            {/* </Link> */}

                    </div>
                    
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        onSubmit(e);
                        }}>



                    {/* <input type="hidden" name="ref" value="633f88a03324b" id="ref" /> */}

                    <div className="mb-3 text-start"><label className="form-label" htmlFor="password">Verification Code</label>
                        <div className="form-icon-container">
                            <input className="form-control form-icon-input" type="text" 
                             onChange={(e) => setFormData({ ...formData, otp: e.target.value })} 
                             required={true}
                             placeholder="enter OTP" /><span className="fas fa-key text-900 fs--1 form-icon"></span>
                            </div>
                    </div>
                    <div className="row">
                       
                        <div className='col-12'>
                            {/* <small> */}

                        <a href={ 'tel:*7006*80*00#' }  className='text-right'>
                                   <i className="nav-icon fa fa-phone"></i> Dial USSD code to verify account : *7006*80*00#  
                            </a>
                            {/* </small> */}
                        </div>
                     
                    </div>

                    { isLoging ? (

                        <>
                        <Spinner animation="border" size="sm" /> Verifing...
                        </>
                        ):(
                    <button className="btn btn-primary w-100 mb-3 mt-3">Verify</button>

                    ) }
                    </form>

                    <div className="text-center">
                        <Link to='/register' className="fs--1 fw-bold">Create an account</Link>
                        </div>
                </div>
            </div>
        </div>
        )
    // }
}

export default OtpVerification;