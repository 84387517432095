import React from 'react';

const NumberFormater = ({num}) => {

  const number = parseFloat(num);
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const formattedNumber = formatter.format(number);

  return <span>{formattedNumber}</span>;
};

export default NumberFormater;