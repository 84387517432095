import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button, Spinner } from 'react-bootstrap';
import AuthenticateService from './AuthenticateService';
import axios from 'axios';
import Sidebar from './Sidebar';
import Header from './Header';
import TransactionHistory from './extra/dashboard/TransactionHistory';
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select'
import Swal from 'sweetalert2';
import PreLoader from './extra/PreLoader';

const WalletTransfer = () => {


    const { http, user } = AuthenticateService();
    const { token, logout } = AuthenticateService();
    const logoutUser = () => {
        if (token != undefined) {
            logout();
        }
    }

    const [accountNumber, setAccountNumber] = useState('');
    const [accountName, setAccountName] = useState('');
    const isAccountNumberValid = accountNumber.length === 10;
    const [accountNameVerified, setAccountNameVerified] = useState(false);
    const [description, setDescription] = useState('');
    const [amount, setAmount] = useState('');
    const [banks, setBanks] = useState([]);
    const [transactionPin, setTransactionPin] = useState('');
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isBuying, setIsBuying] = useState(false);
    const [isInitialising, setIsInitialising] = useState(false);
    const [transactionRefrence, settransactionRefrence] = useState(false);

    const [isVerifying, setIsVerifying] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const defaultAmount = [500, 1000, 2000, 5000, 10000,  20000];

    const [selectedBank, setSelectedBank] = useState(null);
   

    const verifyAccountNumber = async (number) => {
        // console.log(number);
        setAccountNumber(number);
        try {
            setIsVerifying(true);
            const response = await http.post('/transactions/verify-wallet-account', {
                // bank_code: selectedBank.value,
                account_number: number,
            });

            // console.log(response);
            // console.log(response.data);
            if (response.data.status === true) {
                toast.info(response.data.message);
                setAccountNameVerified(true)
                // console.log(response.data);
                setAccountName(response.data.data.first_name+" "+ response.data.data.last_name)

                // Update any necessary state variables or perform additional actions here
            } else {
                toast.warning(response.data.message);
                setAccountNameVerified(false)
                setAccountName("");
            }

            setIsVerifying(false);
        } catch (error) {
          if(error.response){
            if (error.response.status==401){
                logout();
            }

            if (error.response.status==409){
                toast.warning(error.response.data.message);
            }
            


        }else{
            toast.error('Something Went Wrong')
        }
            setIsVerifying(false);
            // console.log(error);
            setAccountName("");
            // toast.error('Account verification failed: ');
        }
        // }
    };






    // useEffect(() => {
    //     getAllBanks();
    // }, []);




    const validateForm = () => {
        if (!accountNumber  || !description || !accountName || !amount) {
            toast.error('Please fill in all fields');
            return false;
        }
        return true;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            setIsInitialising(true)
            

                    try {


                    const response = await http.post('/transactions/initiate-transaction', {
                        final_amount: amount,
                        transaction_type: 4,
                        destination_account: accountNumber,
                        receipient_name: accountName,
                        // bank_code: selectedBank.value,
                        description: description,
                    }).then((res) => {
                        // toast.info(res.data.message);
                        settransactionRefrence(res.data.data.transaction_reference);
                        setIsInitialising(false);
                        setShowPasswordModal(true);

                    })
               } catch (error) {
                if (error.response.status==401){
                    logout();
                }
                    setIsInitialising(false)
                    Swal.fire({
                        title: "Failed",
                        text: 'Initialization Failed: '+ error.response.data.message,
                        icon: 'error',
                        confirmButtonText: 'OK',
                    })

                    // toast.error('Initialization Failed:, ' + error.response.data.message);

                }


            }else{

            }

        // }
        // } catch (error) {
        //     setIsBuying(false)
        //     toast.error('Transfer Failed, ' + error.response.data.message);

        // }


    };



    const handleContinue = async () => {
        if (validateForm()) {
            try {

                setIsBuying(true)

                const response = await http.post('/transactions/wallet-transfer', {
                    final_amount: amount,
                    transaction_reference: transactionRefrence,
                    destination_account: accountNumber,
                    receipient_name: accountName,
                    // bank_code: selectedBank.value,
                    transaction_pin: transactionPin,
                    description: description,

                }).then((trans) => {
                    // console.log(res);
                    if (trans.data.status === true) {

                        Swal.fire({
                            title: trans.data.message,
                            text: 'You successfully transfer ' + amount + " to " + accountName + " (" + accountNumber + ") \nReference Number : " + transactionRefrence,
                            icon: 'success',
                            confirmButtonText: 'OK',
                        })
                        setIsBuying(false);
                        setAmount('');
                        setAccountName('');
                        setSelectedBank('');
                        setAccountNumber('');
                        setDescription('');
                        setTransactionPin('');
                        setShowPasswordModal(false);
                        setIsBuying(false)
                    }

                    else {
                        Swal.fire({
                            title: response.data.message,
                            text: 'Your transfer of ' + amount + " to " + accountName + " (" + accountNumber + ") failed <br\> Reference Number : " + transactionRefrence,
                            icon: 'error',
                            confirmButtonText: 'OK',
                        })
                        setIsBuying(false);

                    }
                })
                    .catch((error) => {
                        setIsBuying(false)

                        toast.error('Transfer Failed, ' + error.response.data.message);

                    });
            } catch (error) {
                if (error.response.status==401){
                    logout();
                }
                setIsBuying(false)
                toast.error('Transfer Failed, ' + error.response.data.message);

            }
        }
    };



    return (
        <>
            {isLoading ? (
                
                <PreLoader/>

            ) : (
                <>
                    <Sidebar />
                    <Header user={user} />

                    <div className="content">
                        <form className="mb-9" onSubmit={handleSubmit}>
                            <div className="row g-5">
                                <div className="col-xl-8">
                                    <h4 className="fs-1 mb-4">Fund Tranfer</h4>
                                    <div className="row gx-3 gy-4">

                                       


                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-floating">
                                                <input
                                                    className="form-control"
                                                    id="floatingEventInput"
                                                    type="text"
                                                    placeholder="Account Number"
                                                    maxLength={10}
                                                    
                                                    onChange={(e) => {
                                                        setAccountNumber(e.target.value);
                                                        if (e.target.value.length === 10) {
                                                          verifyAccountNumber(e.target.value);
                                                        //   console.log(e.target.value.length);
                                                        //   console.log(e.target.value);
                                                          
                                                        //   setAccountNumber(e.target.value);
                                                        //   console.log(accountNumber);
                                                        }
                                                      }}
                                                      value={accountNumber}
                                                    
                                                    required
                                                />
                                                <label htmlFor="floatingEventInput">Account Number</label>
                                            </div>
                                        </div>


                                        <div className="col-sm-6 col-md-6">

                                            <div className="form-floating">
                                                <input
                                                    className="form-control"
                                                    id="floatingEventInput"
                                                    type="text"
                                                    readOnly={true}
                                                    placeholder="Account Name"
                                                    value={accountName}
                                                    disabled={!accountNameVerified}

                                                />
                                                { isVerifying ? (
                                                <button
                                                    type="button"
                                                    style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        right: '10px',
                                                        transform: 'translateY(-50%)',
                                                        background: 'transparent',
                                                        border: 'none',
                                                        cursor: 'pointer',
                                                    }}
                                                    className="btn btn-link password-toggle"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                >
                                                    <Spinner animation="border" size="sm" />
                                                </button>

                                                    ): (
                                                        ""
                                                    )}


                                                <label htmlFor="floatingEventInput">Account Name</label>
                                            </div>
                                        </div>

                                        


                                        <div className="col-sm-6 col-md-6">
                                            <div className="row">
                                                {defaultAmount.map((amt, index) => (
                                                    <div key={index} className="col-4">
                                                        <span
                                                            onClick={() => setAmount(amt)}
                                                            className={`btn btn-sm  rounded-pill w-100 me-3 mb-2  fs--1 fs-sm-0 ${amount === amt ? 'btn-warning' : 'btn-outline-warning'}`}
                                                        >
                                                            {amt}

                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-floating">
                                                <input
                                                    className="form-control"
                                                    id="floatingEventInput"
                                                    type="number"
                                                    step={2}
                                                    placeholder="Amount"
                                                    value={amount}
                                                    onChange={(e) => setAmount(e.target.value)}
                                                    required
                                                />
                                                <label htmlFor="floatingEventInput">Amount</label>
                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-6 gy-6">
                                            <div className="form-floating">
                                                <textarea
                                                    className="form-control"
                                                    id="floatingProjectDescription"
                                                    placeholder="Leave a comment here"
                                                    style={{ height: '50px' }}
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                    required
                                                ></textarea>
                                                <label htmlFor="floatingProjectDescription">Naration</label>
                                            </div>
                                        </div>







                                        <div className="col-12 col-sm-auto col-xl-4">
                                            <div className="d-flex">
                                                <Button variant="primary"
                                                    type="submit"


                                                    disabled={isInitialising}>
                                                    {isInitialising ? (
                                                        <>
                                                            <Spinner animation="border" size="sm" /> Initialising...
                                                        </>
                                                    ) : (
                                                        "Continue"
                                                    )}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>



                        <TransactionHistory transaction_type={4} title="Wallet Transactions"/>



                        <Footer />
                    </div>


                    <Modal show={showPasswordModal} onHide={() => setShowPasswordModal(false)} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>Enter Transaction PIN</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-floating">
                                <input
                                    className="form-control"
                                    type={showPassword ? 'number' : 'password'}
                                    maxlength={4}

                                    placeholder="Transaction Pin"
                                    value={transactionPin}
                                    onChange={(e) => setTransactionPin(e.target.value)}
                                    required
                                />

                                <button
                                    type="button"
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        right: '10px',
                                        transform: 'translateY(-50%)',
                                        background: 'transparent',
                                        border: 'none',
                                        cursor: 'pointer',
                                    }}
                                    className="btn btn-link password-toggle"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                </button>


                                <label htmlFor="floatingEventInput">Transaction PIN</label>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary"

                                onClick={() => setShowPasswordModal(false)}>
                                Cancel
                            </Button>

                            <Button variant="primary"


                                onClick={handleContinue} disabled={isBuying}>
                                {isBuying ? (
                                    <>
                                        <Spinner animation="border" size="sm" /> Loading...
                                    </>
                                ) : (
                                    "Transfer Now"
                                )}
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <ToastContainer />
                </>
            )

            }
        </>
    );
}

export default WalletTransfer;
