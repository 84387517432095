import React, { useState } from 'react';
import AuthenticateService from '../auth/AuthenticateService';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
const ForgetPassword = () => {
  const [phoneNumber, setphoneNumber] = useState('');
  const { http, setToken } = AuthenticateService();
  const [error, setError] = useState('');
  const [isLoging, setIsLoging] = useState(false)

  const navigate = useNavigate();

  const handPasswordRequest = () => {
    setIsLoging(true)
    http.post('/users/forgot-password-send-otp', {
      phone_number: phoneNumber,
    })

      .then((res) => {
        // console.log(res);

        if (res.status === 200) {
          setError('');
          toast.success(res.data.message);
          sessionStorage.setItem('resetPhoneNumber', phoneNumber);
          navigate('/reset-password');
        } else {
          setError(res.message);
          setIsLoging(false)
        }
      })
      .catch((error) => {

        // setError('Failed to resend ')users/resend-otp
        // console.log(error.response.data.message);
        toast.error('Resend Failed : ' + error.response.data.message);
        setIsLoging(false)

      });
  }

  // console.log(http);

  return (
    <div class="container">
      <div class="row flex-center min-vh-100 py-5">
        <div class="col-sm-10 col-md-8 col-lg-5 col-xxl-4">
          <a className="d-flex flex-center text-decoration-none mb-4" href="/">
            <div className="d-flex align-items-center fw-bolder fs-5 d-inline-block p-4">
              {/* <img src="https://safe.wbalite.com/assets/images/wiredbanking_logo_outline.png" alt="wba" width="100" /> */}
            </div>
          </a>

          <div class="px-xxl-5">
            <div class="text-center mb-6">
              <h4 class="text-1000">Forgot your password?</h4>
              <p class="text-700 mb-5">Enter your phone number below and we will send</p>
              <ToastContainer />
              <h6 className="login-box-msg text-uppercase">
                <span className="error-message text-danger">{error}</span>
              </h6>
              <form class="d-flex align-items-center mb-5" onSubmit={(e) => {
                e.preventDefault();
                handPasswordRequest();
              }}>

                <input class="form-control flex-1" type="tel"
                  value={phoneNumber}
                  onChange={(e) => {
                    setphoneNumber(e.target.value)
                  }}
                  required={true}
                  placeholder="Phone Number" />



                {isLoging ? (

                  <>
                    <Spinner animation="border" variant='info' size="sm" /> Sending...
                  </>
                ) :
                  (<button class="btn btn-primary ms-2">

                    Send<span class="fas fa-chevron-right ms-2"></span>

                  </button>

                  )}
              </form>
              <a class="fs--1 fw-bold" href="forgot-password.html#!">Still having problems?</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgetPassword