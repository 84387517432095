import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { BarChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
} from 'echarts/components';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

const WeeklyTransactionPerformance = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    echarts.use([
      BarChart,
      TitleComponent,
      TooltipComponent,
      GridComponent,
      DatasetComponent,
      TransformComponent,
      LabelLayout,
      UniversalTransition,
      CanvasRenderer,
    ]);

    const myChart = echarts.init(chartRef.current);

    const defaultData = [
      {
        id: 1,
        type: 'Bet',
        amount: 100,
        recipient: 'John Doe',
      },
      {
        id: 2,
        type: 'Data',
        amount: 50,
        provider: 'MTN',
        plan: '1GB',
      },
      {
        id: 3,
        type: 'Airtime',
        amount: 20,
        mobileNumber: '1234567890',
      },
      {
        id: 4,
        type: 'Transfer',
        amount: 200,
        provider: 'DStv',
        package: 'Premium',
      },
    ];

    const transformedData = defaultData.map((item) => ({
      name: item.type,
      value: item.amount,
    }));

    myChart.setOption({
      xAxis: {
        type: 'category',
        data: transformedData.map((item) => item.name),
      },
      yAxis: {},
      series: [
        {
          name: '0',
          type: 'pie',
          data: transformedData.map((item) => item.value),
        radius:["100%","87%"],
        avoidLabelOverlap:!1,
        emphasis:{scale:!1,itemStyle:{color:"inherit"}},
        itemStyle:{borderWidth:2,borderColor:'#FFFFFF'},
        label:{show:!0,position:"center",formatter:"{a}",fontSize:23,color:'#000000'}
        },
      ],
    });

    return () => {
      myChart.dispose();
    };
  }, []);

  const defaultColor = ['bg-success', 'bg-info', 'bg-primary', 'bg-dark', 'bg-warning'];
  const defaultData = [
    {
      id: 1,
      type: 'Bet',
      amount: 100,
      recipient: 'John Doe',
    },
    {
      id: 2,
      type: 'Data',
      amount: 50,
      provider: 'MTN',
      plan: '1GB',
    },
    {
      id: 3,
      type: 'Airtime',
      amount: 20,
      mobileNumber: '1234567890',
    },
    {
      id: 4,
      type: 'Transfer',
      amount: 200,
      provider: 'DStv',
      package: 'Premium',
    },
  ];

  return (
    <div className="card h-100">
      <div className="card-body">
        <div className="d-flex justify-content-between">
          <div>
            <h5 className="mb-2">Transactions</h5>
            <h6 className="text-700">Last 7 days</h6>
          </div>
        </div>
        <div className="pb-4 pt-3">
          <div ref={chartRef} style={{ height: '115px', width: '100%' }}></div>
        </div>
        <div>
          {defaultData.map((item) => (
            <div className="d-flex align-items-center mb-2" key={item.id}>
              <div className={`bullet-item ${defaultColor[item.id - 1]} me-2`}></div>
              <h6 className="text-900 fw-semi-bold flex-1 mb-0">{item.type}</h6>
              <h6 className="text-900 fw-semi-bold mb-0">{item.amount}</h6>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WeeklyTransactionPerformance;
