import { React } from 'react';

const BillPayment = () => {

    return (

        <div className="ecommerce-homepage pt-5 mb-9">
            {/* ============================================*/}
            {/* <section> begin ============================*/}
            <section className="py-0">
                <div className="container-small">
                    <div className="scrollbar">
                        <div className="d-flex justify-content-between">
                            <a className="icon-nav-item" href="homepage.html#!">
                                <div className="icon-container mb-2"><span className="fs-4 uil uil-mobile-android" /></div>
                                <p className="nav-label">Mobile</p>
                            </a>
                            <a className="icon-nav-item" href="homepage.html#!">
                                <div className="icon-container mb-2"><span className="fs-4 uil uil-monitor" /></div>
                                <p className="nav-label">Electronics</p>
                            </a>
                            <a className="icon-nav-item" href="homepage.html#!">
                                <div className="icon-container mb-2"><span className="fs-4 uil uil-estate" /></div>
                                <p className="nav-label">Home</p>
                            </a>
                            <a className="icon-nav-item" href="homepage.html#!">
                                <div className="icon-container mb-2"><span className="fs-4 uil uil-lamp" /></div>
                                <p className="nav-label">Dining</p>
                            </a>
                            <a className="icon-nav-item" href="homepage.html#!">
                                <div className="icon-container mb-2"><span className="fs-4 uil uil-gift" /></div>
                                <p className="nav-label">Gifts</p>
                            </a>
                            <a className="icon-nav-item" href="homepage.html#!">
                                <div className="icon-container mb-2"><span className="fs-4 uil uil-wrench" /></div>
                                <p className="nav-label">Tools</p>
                            </a>
                            <a className="icon-nav-item" href="homepage.html#!">
                                <div className="icon-container mb-2"><span className="fs-4 uil uil-plane-departure" /></div>
                                <p className="nav-label">Travel</p>
                            </a>
                            <a className="icon-nav-item" href="homepage.html#!">
                                <div className="icon-container mb-2"><span className="fs-4 uil uil-palette" /></div>
                                <p className="nav-label">Others</p>
                            </a>
                        </div>
                    </div>
                </div>{/* end of .container*/}
            </section>{/* <section> close ============================*/}
            {/* ============================================*/}
            {/* ============================================*/}
            {/* <section> begin ============================*/}
            <section className="py-0 px-xl-3">
                <div className="container px-xl-0 px-xxl-3">
                    <div className="row g-3 mb-9">
                        <div className="col-12">
                            <div className="whooping-banner w-100 rounded-3 overflow-hidden">
                                <div className="bg-holder z-index--1 product-bg" style={{ backgroundImage: 'url(../../../assets/img/e-commerce/whooping_banner_product.png)', backgroundPosition: 'bottom right' }} />
                                {/*/.bg-holder*/}
                                <div className="bg-holder z-index--1 shape-bg" style={{ backgroundImage: 'url(../../../assets/img/e-commerce/whooping_banner_shape_2.png)', backgroundPosition: 'bottom left' }} />
                                {/*/.bg-holder*/}
                                <div className="banner-text light">
                                    <h2 className="text-warning-300 fw-bolder fs-lg-5 fs-xxl-6">Whooping <span className="gradient-text">60% </span>Off</h2>
                                    <h3 className="fw-bolder fs-lg-3 fs-xxl-5 text-white light">on everyday items</h3>
                                </div><a className="btn btn-lg btn-primary rounded-pill banner-button" href="homepage.html#!">Shop Now</a>
                            </div>
                        </div>


                    </div>

                </div>{/* end of .container*/}
            </section>{/* <section> close ============================*/}
            {/* ============================================*/}
        </div>
    );

}

export default BillPayment