import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthenticateService from './AuthenticateService';
import Sidebar from './Sidebar';
import Header from './Header';
import { Spinner } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import NumberFormater from './extra/Formater';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import DataTable, { createTheme } from 'react-data-table-component';
import TransactionHistory from './extra/dashboard/TransactionHistory';

const Performance = () => {
    const [transactions, setTransactions] = useState([]);
    const { http, user } = AuthenticateService();
    const { token, logout } = AuthenticateService();

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate]= useState('');
    const logoutUser = () => {
        if (token != undefined) {
            logout();
        }
    }

    const [isLoading, setIsLoading] = useState(true);
    const params = {};
  

   






    

    const getTransactions = async (page) => {
        try {
            const response = await http.get('/transactions/history', {
                params
            });
            if (response.status === 200) {
                const data = response.data.data.data;
                setTransactions(data);
            } else {
                console.log('Error: Non-200 status code');
            }
        } catch (error) {
            // logout();
            console.log(error);
            if (error.response.status == 401) {
                logout();
            }
        } finally {
            setIsLoading(false);
        }
    };
    if (fromDate  && toDate) {
        params.from = fromDate;
        params.to = toDate;
      }

      const initialParams = useRef(params);

    //   console.log(params);
    //   console.log(initialParams);
      useEffect(() => {
        // if (initialParams.current !== params) {
          getTransactions(1);
        // }
      }, []);
      

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true,
        };
        return date.toLocaleDateString("en-US", options);
    };

    const truncateString = (str, maxLength) => {
        if (str.length <= maxLength) {
            return str;
        } else {
            return str.slice(0, maxLength) + '...';
        }
    }

    const statuses = ['Credit','Debit'];
    const statuColors = ['success','danger'];
    const remarkColor = ['warning', 'success', 'danger', 'secondary', 'light', 'primary'];
    const remarkStatus = ['Pending', 'Success', 'Failed', 'Awaiting', 'Reverse', 'Initiate'];
    
    createTheme('dark', {
        background: {
          default: 'transparent',
        },
      });

    const dataTableTitle = [
        { name: 'REFERENCE', selector: row => row.transaction_reference, sortable: true,  classNames: ['align-middle', 'product','white-space-nowrap'],headerClassName:['sort', 'white-space-nowrap', 'align-middle']},
        { name: 'AMOUNT', selector: row => row.amount, sortable: true, },
        { name: 'STATUS', selector: row => row.transaction_status_id, sortable: true, },
        { name: 'REMARKS', selector: row => row.transaction_remarks_id, sortable: true, },
        { name: 'DATE AND TIME', selector: row => row.created_at, sortable: true, },
        { name: 'DESCRIPTION', selector: row => row.description, sortable: true, },
    ];

// console.log(user);


    return (

        <>
            <Sidebar />
            <Header user={user} />


            <div className="content">
                <div className="pb-5">
                    <h3>Performance Overview</h3>

                    {/* <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white pt-7 border-y border-300">
                        <div data-list='{"valueNames":["product","customer","rating","review","time"],"page":6}'>
                           <div className="row align-items-end justify-content-between pb-5 g-3">
                                <div className="col-auto">

                                    <p className="text-700 lh-sm mb-0">All types of transactions</p>
                                </div>
                                <div className="col-12 col-md-auto">
                                    <div className="row g-2 gy-3">
                                        <div className="col-auto flex-1 mr-4">
                                            <div className="search-box">
                                                <form className="position-relative" data-bs-toggle="search" data-bs-display="static">
                                                    <div className="d-flex">
                                                        <div className="d-flex flex-column me-2">
                                                            <label htmlFor="fromDate">From</label>
                                                            <input id="fromDate" className="form-control form-control-sm" onChange={(e)=>{
                                                                setFromDate(e.target.value);
                                                            }} type="date" 
                                                            placeholder="From" 
                                                            aria-label="From"
                                                            max={new Date().toISOString().split('T')[0]}
                                                            />
                                                        </div>
                                                        <div className="d-flex flex-column">
                                                            <label htmlFor="toDate">To</label>
                                                            <input 
                                                            id="toDate" 
                                                            onChange={(e)=>{
                                                                setToDate(e.target.value);
                                                            }} 
                                                            className="form-control form-control-sm" 
                                                            type="date" 
                                                            placeholder="To" 
                                                            aria-label="To"
                                                             max={new Date().toISOString().split('T')[0]}
                                                            
                                                            />
                                                        </div>




                                                    </div>
                                                </form>
                                            </div>





                                        </div>
                                        <div className="col-auto">
                                            <button className="btn btn-sm btn-phoenix-secondary bg-white hover-bg-100 me-2" type="button">All products</button>
                                            <button className="btn btn-sm btn-phoenix-secondary bg-white hover-bg-100" type="button" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent">
                                                <span className="fas fa-ellipsis-h fs--2"></span></button>
                                            <ul className="dropdown-menu dropdown-menu-end">
                                                {statuses.map((item) => (
                                                    <li><a className="dropdown-item" href="#">{item}</a></li>
                                                ))}

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div> 

                           <div className="table-responsive mx-n1 px-1 scrollbar">
                                <table className="table fs--1 mb-0 border-top border-200">
                                    <thead>
                                        {isLoading ? (
                                            <Spinner animation="border" className='text-center' variant="info" />
                                        ) : (
                                            <tr>

                                                <th className="sort white-space-nowrap align-middle" scope="col" data-sort="product">REFERENCE</th>
                                                <th className="sort align-middle" scope="col" data-sort="customer">AMOUNT</th>
                                                <th className="sort text-start ps-5 align-middle" scope="col" data-sort="status">STATUS</th>
                                                <th className="sort text-start ps-5 align-middle" scope="col" data-sort="status">REMARKS</th>
                                                <th className="sort text-start ps-5 align-middle" scope="col" data-sort="status">DATE AND TIME</th>
                                                <th className="sort text-start ps-5 align-middle" scope="col" data-sort="status">DESCRIPTION</th>
                                            </tr>
                                        )}
                                    </thead>
                                    <tbody className="list" id="table-latest-review-body">

                                        {isLoading ? (

                                            <Spinner animation="border" variant="primary" />
                                        )
                                            :
                                            (transactions.length > 0 ? (transactions.map((transaction, index) => (
                                                <tr className="hover-actions-trigger btn-reveal-trigger position-static" key={index}>
                                                    <td className="align-middle product white-space-nowrap">{transaction.transaction_reference}</td>
                                                    <td className="align-middle customer white-space-nowrap">{transaction.amount}</td>
                                                    <td className="align-middle text-start ps-5 status">
                                                        <span className={`badge badge-phoenix fs--2 badge-phoenix-${statuColors[transaction.transaction_status_id - 1]}`}>
                                                            <span className="badge-label">{statuses[transaction.transaction_status_id - 1]}</span>
                                                            <span className="ms-1" data-feather="check" style={{ height: '12.8px', width: '12.8px' }}></span>
                                                        </span>
                                                    </td>


                                                    <td className="align-middle text-start ps-5 status">
                                                        <span className={`badge badge-phoenix fs--2 badge-phoenix-${remarkColor[transaction.credit_debit_type_id - 1]}`}>
                                                            <span className="badge-label">{remarkStatus[transaction.credit_debit_type_id - 1]}</span>
                                                            <span className="ms-1" data-feather="check" style={{ height: '12.8px', width: '12.8px' }}></span>
                                                        </span>
                                                    </td>
                                                    <td className="align-middle text-start time white-space-nowrap">
                                                        <div className="hover-hide">
                                                            <h6 className="text-1000 mb-0"> {formatDate(transaction.created_at)} </h6>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle customer white-space-nowrap" style={{ wordWrap: 'break-word' }}>
                                                        {truncateString(transaction.description, 50)}
                                                    </td>



                                                </tr>
                                            ))) : (
                                                <p>No transactions found.</p>
                                            )
                                            )
                                        }






                                    </tbody>
                                </table>
                            </div> 



                         
                          
                        </div>
                    </div> */}



                        
                        <TransactionHistory title ="All History Performance"/>


                      

                </div>
            </div>



        





        </>


    );
}

export default Performance;
