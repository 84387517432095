import React, { useEffect, useState } from "react";
import { Routes, useNavigate, Route, useParams } from "react-router-dom";
import "./vendors/simplebar/simplebar.min.css";

// import './assets/css/theme-rtl.min.css';
import "./assets/css/theme.min.css";
// import './assets/css/user-rtl.min.css';
import "./assets/css/user.min.css";
import "./assets/fontawesome-free/css/all.min.css";

// import { Helmet } from 'react-helmet';
import Login from "./components/guest/Login";
import Register from "./components/guest/Register";
import OtpVerification from "./components/guest/OtpVerification";
import ForgetPassword from "./components/guest/ForgetPassword";
import NoPage from "./components/guest/404";
import Dashboard from "./components/auth/Dashboard";
import ChatHelp from "./components/auth/extra/Chat";
import Account from "./components/auth/Account";
import Performance from "./components/auth/Performance";
import Airtime from "./components/auth/Airtime";
import DataSubscription from "./components/auth/DataSubscription";
import Transfer from "./components/auth/Transfer";
import WalletTransfer from "./components/auth/Wallet";
import TVSubscription from "./components/auth/TVSubscription";
import Loan from "./components/auth/Loan";
import AtmCard from "./components/auth/AtmCard";
import ResetPassword from "./components/guest/ResetPassword";
import BillPayment from "./components/auth/BillsPayment";
import ElectricitySubscription from "./components/auth/ElectricitySubscription";
import POS from "./components/auth/POS";
import AuthenticateService from "./components/auth/AuthenticateService";
import axios from "axios";
import { toast } from "react-toastify";
import Network from "./components/auth/Network";
import Betting from "./components/auth/Betting";
import UssdPOS from "./components/auth/UssdPos";
import Profile from "./components/auth/Profile";
function App() {
  // const [companyName, setCompanyName] = useState('');
  const [company, setCompany] = useState("");
  const { getCompany } = AuthenticateService();
  const [isCompanySet, setIsCompanySet] = useState(false);
  // const { company } = useParams();

  // console.log(useParams());

  

  const getCompanyDetails = async (company) => {
    try {
      const response = await axios.get(
        "https://safe.wbalite.com/vas/api/company/" + company
      );
      if (response.status === 200) {
        const data = response.data.data;
        setCompany(data);
      } else {
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.response);
      } else {
        toast.error("Something Went Wrong");
      }
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const companyName = urlParams.get("company");
    let host = window.location.host.split(".");
    if (host.length >= 3) {
      let subdomain = host[0];
      getCompanyDetails(subdomain);
    }

    // getCompanyDetails('ebowallet');

  }, []);

  // console.log(company);
  

  return (
    <main className="main" id="top">
      <div className="container-fluid px-0" data-layout="container">
        {/* <h1>company here</h1> https://ebowallet*/}
        <Routes>
          <Route path="/" exact element={<Login company={company} />}></Route>
          <Route
            path="/register"
            element={<Register company={company} />}
          ></Route>
          <Route path="/reset-password" element={<ResetPassword />}></Route>
          <Route path="/otp" element={<OtpVerification />}></Route>
          <Route path="/forget-password" element={<ForgetPassword />}></Route>
          <Route path="*" element={<NoPage />}></Route>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/account" element={<Account />} />
          <Route path="/performance" element={<Performance />} />
          <Route path="/airtime" element={<Airtime />} />
          <Route path="/transfer" element={<Transfer />} />
          <Route path="/wallet-transfer" element={<WalletTransfer />} />
          <Route path="/data-subscription" element={<DataSubscription />} />
          <Route
            path="/electricity-subscription"
            element={<ElectricitySubscription />}
          />
          <Route path="/bills-payment" element={<BillPayment />} />

          <Route path="/cable-tv-subscription" element={<TVSubscription />} />

          <Route path="/loan" element={<Loan />} />
          <Route path="/profile" element={<Profile />} />

          <Route path="/atm-card" element={<AtmCard />} />
          <Route path="/pos-terminal" element={<POS />} />
          <Route path="/network" element={<Network />} />
          <Route path="/soft-ussd-pos" element={<UssdPOS />} />
        </Routes>
      </div>

      <ChatHelp />
    </main>
  );
}

export default App;
