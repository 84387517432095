import { React, useState, useEffect } from 'react';
import AuthenticateService from '../AuthenticateService';
import { toast } from 'react-toastify';

const ChatHelp =()=>{
  const [users, setUser] = useState(null);
  const {user, http} = AuthenticateService();
  const { token, logout } = AuthenticateService();

  const getUserDetails = async () => {
    try {
        const response = await http.get('/users/get-details', {
            // params: { page: 1 },
        });
        if (response.status === 200) {
            const data = response.data.data;
            // console.log(data);
            setUser(data);
            // setIsLoading(false)
        } else {
            // console.log('Error: Non-200 status code');
            // logout()
        }
    } catch (error) {
        // logout();
        if(error.response){
          if (error.response.status==401){
              logout();
          }
      }else{
          toast.error('Something Went Wrong')
      }
    }
};

useEffect(() => {
  getUserDetails();

}, []);


// console.log(user);

    return (
        <div className="support-chat-container show">
        <div className="container-fluid support-chat">
          <div className="card bg-white">
            <div className="card-header d-flex flex-between-center px-4 py-3 border-bottom">
              <h5 className="mb-0 d-flex align-items-center gap-2">Our Support<span className="fa-solid fa-circle text-success fs--3"></span></h5>
              <div className="btn-reveal-trigger"><button className="btn btn-link p-0 dropdown-toggle dropdown-caret-none transition-none d-flex" type="button" id="support-chat-dropdown" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"><span className="fas fa-ellipsis-h text-900"></span></button>
                <div className="dropdown-menu dropdown-menu-end py-2" aria-labelledby="support-chat-dropdown"><a className="dropdown-item" href="sign-up.html#!">Request a callback</a><a className="dropdown-item" href="sign-up.html#!">Search in chat</a><a className="dropdown-item" href="sign-up.html#!">Show history</a><a className="dropdown-item" href="sign-up.html#!">Report to Admin</a><a className="dropdown-item btn-support-chat" href="sign-up.html#!">Close Support</a></div>
              </div>
            </div>
            <div className="card-body chat p-0">
              <div className="d-flex flex-column-reverse scrollbar h-100 p-3">
                <div className="text-end mt-6">
                  <a className="mb-2 d-inline-flex align-items-center text-decoration-none text-1100 hover-bg-soft rounded-pill border border-primary py-2 ps-4 pe-3" 
                  href={"tel:+"+ (user !=null ? user.phone:'')}
                  >
                    <p className="mb-0 fw-semi-bold fs--1">Call : { user !=null ? user.phone:''}</p>
                    <span className="fa fa-phone text-primary fs--1 ms-3"></span>
                  </a>
                  <br></br>

                  

                  <a className="mb-2 d-inline-flex align-items-center text-decoration-none text-1100 hover-bg-soft rounded-pill border border-primary py-2 ps-4 pe-3" 
                  href={user !=null ?  `https://wa.me/${user.company.phone}`:''}>
  <p className="mb-0 fw-semi-bold fs--1">WhatsApp Us: { user !=null ? user.phone:''}</p>
  <span className="fab fa-whatsapp text-primary fs--1 ms-3"></span>
</a>

<br />

<a className="mb-2 d-inline-flex align-items-center text-decoration-none text-1100 hover-bg-soft rounded-pill border border-primary py-2 ps-4 pe-3" 
href={user !=null ? `mailto:${user.company.email}`:''}>
  <p className="mb-0 fw-semi-bold fs--1">Email Us{ user !=null ? user.email:''}</p>
  <span className="fa fa-envelope text-primary fs--1 ms-3"></span>
</a>


                  </div>
                <div className="text-center mt-auto">
                  <div className="avatar avatar-3xl status-online"><img className="rounded-circle border border-3 border-white" 
                  src="https://prium.github.io/phoenix/v1.11.0/assets/img/team/30.webp" 
                  alt="" />
                  </div>
                  <h5 className="mt-2 mb-3">Support Team</h5>
                  <p className="text-center text-black mb-0">Ask us anything – we’ll get back to you here or by email within 24 hours.</p>
                </div>
              </div>
            </div>
            <div className="card-footer d-flex align-items-center gap-2 border-top ps-3 pe-4 py-3">
              <div className="d-flex align-items-center flex-1 gap-3 border rounded-pill px-4">
                <input className="form-control outline-none border-0 flex-1 fs--1 px-0" type="text" placeholder="Write message" />
                <label className="btn btn-link d-flex p-0 text-500 fs--1 border-0" htmlFor="supportChatPhotos">
                  <span className="fa-solid fa-image"></span></label>
                  <input className="d-none" type="file" accept="image/*" id="supportChatPhotos" />
                  <label className="btn btn-link d-flex p-0 text-500 fs--1 border-0" htmlFor="supportChatAttachment">
                    
                     <span className="fa-solid fa-paperclip"></span></label>
                     <input className="d-none" type="file" id="supportChatAttachment" />
                     </div><button className="btn p-0 border-0 send-btn"><span className="fa fa-chat fs--1"></span>
                     </button>
            </div>
          </div>
        </div>
        {user != null ? (
            <button className="btn p-0 border border-200 btn-support-chat">
              <span className="fs-0 btn-text text-primary text-nowrap">Support</span>
              <span className="fa-solid fa-circle text-success fs--1 ms-2"></span>
              <span className="fa-solid fa-chevron-down text-primary fs-1"></span>
            </button>
             ):(<></>)}

        
      </div>
    );
}


export default ChatHelp;